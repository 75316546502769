import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Table,
  Input,
  Upload,
  Typography,
  Space,
  notification,
  Spin,
  Card,
  Row,
  Select,
  message,
  Modal,
  Form,
  InputNumber,
  Checkbox,
} from "antd";

import config from "../../../config";

// icons
import check from "../../../assets/icons/check.png";
import edit1 from "../../../assets/icons/edit.png";
import up2 from "../../../assets/icons/Polygon 1.png";
import down2 from "../../../assets/icons/Polygon 2.png";
import delete1 from "../../../assets/icons/trash.png";
import add from "../../../assets/icons/add-button.svg";
import folder from "../../../assets/icons/folder-open.svg";
import downloadIcon from "../../../assets/icons/download-icon.png";
import circle from "../../../assets/icons/add2.svg";
import book from "../../../assets/icons/book.svg";
import edit2 from "../../../assets/icons/edit-2.svg";
import tick from "../../../assets/icons/tick-circle-gray.svg";
import arrowRight from "../../../assets/icons/arrow-circle-right.svg";
import arrowLeft from "../../../assets/icons/arrow-circle-left.svg";
import mkvIcon from "../../../assets/icons/mkv.svg";
import mp4Icon from "../../../assets/icons/mp4.svg";
import powerIcon from "../../../assets/icons/power.svg";
import pdfIcon from "../../../assets/icons/pdf.svg";
import excelIcon from "../../../assets/icons/excel.svg";
import wordIcon from "../../../assets/icons/word.svg";
import eye from "../../../assets/icons/eye.png";

import ModalStep3 from "./ModalStep3";
import "../style.css";
import { controller } from "../controller";

const { Title } = Typography;

const TreatmentCardStep2 = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [item, setItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [note, setNote] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [videoFile, setVideoFile] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([]);
  const [edit, setEdit] = useState({});
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [updatingPriority, setUpdatingPriority] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [data, setData] = useState([]);
  const [save, setSave] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisible5, setIsModalVisible5] = useState(false);
  const [editState, setEditState] = useState({});
  const [expandedRowInputValues, setExpandedRowInputValues] = useState({});
  const [id, setId] = useState(null);
  const [treatmentId, setTreatmentId] = useState(null);
  const [eduid, setEduId] = useState(null);
  const [edu, setEdu] = useState([]);
  const [eduvalue, setEduValue] = useState([]);
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [desc2, setDesc2] = useState("");
  const [procedures, setProcedures] = useState([]);
  const [disableadd, setDisableAdd] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedprocedure, setSelectedProcedure] = useState("");
  const [startDates, setStartDates] = useState("");
  const [endDates, setEndDates] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [current, setCurrent] = useState(1);
  const [itemlist, setItemList] = useState([]);
  const [edulist, setEduList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [modaledit, setModalEdit] = useState(false);
  const [modaledit2, setModalEdit2] = useState(false);
  const [editpro, setEditPro] = useState({});
  const [editingProcedureIndex, setEditingProcedureIndex] = useState(null);
  const [editedProcedures, setEditedProcedures] = useState({});
  const [itemlist2, setItemlist2] = useState([]);
  const [pms, setPms] = useState([]);
  const [treatmentProcedure, setTreatmentProcedure] = useState([]);
  const [newTreatmentProcedure, setNewTreatmentProcedure] = useState(false);
  const [selectModal, setSelectModal] = useState(false);
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
  const [treatmentName, setTreatmentName] = useState("");
  const [search, setSearch] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [new1, setNew1] = useState(false);
  const [uploadKey, setUploadKey] = useState(Math.random());
  // const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [newTab, setNewTab] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [filteredData2, setFilteredData2] = useState([]);
  const [itemize, setItemize] = useState({});
  const [isGettingTreatmentPlans, setIsGettingTreatmentPlans] = useState(true);

  const [modalState, setModalState] = useState({
    isVisible: false,
    deleteId: null,
  });
  const [loading, setLoading] = useState(false);
  // const [loading2, setLoading2] = useState(false);

  const prevIdRef = useRef();

  const handleProcedureInputChange = (value, procIndex, fieldName) => {
    const updatedValue = value === "" ? "" : value;

    setEditedProcedures({
      ...editedProcedures,
      [procIndex]: {
        ...editedProcedures[procIndex],
        [fieldName]: updatedValue,
      },
    });
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return pdfIcon;
      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "docx":
        return wordIcon;
      case "xlsx":
        return excelIcon;
      case "pptx":
        return powerIcon;
      case "zip":
      case "rar":
        return folder; // Default folder icon for compressed files
      case "mp4":
        return mp4Icon;
      case "mkv":
        return mkvIcon;
      case "aspx":
        return folder; // Assuming folder icon for this type
      default:
        return folder; // Default folder icon for unknown types
    }
  };

  // Get the file type (assuming `file.file_type` contains the file type)

  // Function to save the edited procedure
  // Function to save the edited procedure
  const saveEditedProcedure = async (procIndex, record, procedureId) => {
    const expandedRowData = editedProcedures[procIndex]; // Get the edited data for the current procedure

    try {
      // Create the object with only the fields that have a value
      const dataToSend = {};

      if (expandedRowData.procedure_code_description) {
        dataToSend.procedure_code = expandedRowData.procedure_code_description;
      }
      if (expandedRowData.tooth) {
        dataToSend.tooth = expandedRowData.tooth || "-";
      }
      // if (expandedRowData.discount) {
      dataToSend.discount = expandedRowData.discount;
      // }
      if (expandedRowData.insurance_estimate !== null && expandedRowData.insurance_estimate !== undefined) {
        dataToSend.insurance_estimate = expandedRowData.insurance_estimate;
      }

      if (expandedRowData.estimated_cost) {
        dataToSend.estimated_cost = expandedRowData.estimated_cost;
      }

      // Send data to the server only with the existing fields
      const response = await controller.UpdateProcedures(procedureId, dataToSend);

      if (response.status === 200) {
        // Update local table state after successful response
        setItemlist2((prevItemList) =>
          prevItemList.map((item) =>
            item.key === record.key // Update the specific row that matches the current record
              ? {
                  ...item,
                  treatment_plans: item.treatment_plans.map((plan) => ({
                    ...plan,
                    treatment_procedures: plan.treatment_procedures.map((procedure, index) =>
                      index === procIndex
                        ? {
                            ...procedure,
                            ...dataToSend, // Only update the fields that were sent
                          }
                        : procedure
                    ),
                  })),
                }
              : item
          )
        );
        message.success("Procedure Saved successfully!");

        // Exit edit mode
        setEditingProcedureIndex(null);
        setEditedProcedures({});
        handleReadData2();
        setDisableAdd(false);
      } else {
        console.error("Error updating procedure:", response.message);
        // Optionally, handle server errors here (e.g., display an error message)
        setEditingProcedureIndex(null);
        setEditedProcedures({});
      }
    } catch (error) {
      console.error("Error saving procedure:", error);
      // Handle network or unexpected errors
    }
    setEditingProcedureIndex(null);
    setEditedProcedures({});
  };

  // Function to cancel the edit mode
  const cancelEditProcedure = () => {
    setEditingProcedureIndex(null);
    setEditedProcedures({});
  };

  // Function to delete a procedure
  const deleteProcedures2 = (procedureId) => {
    // Assuming the procedure ID is unique, and we can delete based on ID
    const updatedTreatmentPlans = itemlist[0].treatment_plans.map((plan) => ({
      ...plan,
      treatment_procedures: plan.treatment_procedures.filter((proc) => proc.id !== procedureId),
    }));

    const updatedItemlist = [...itemlist];
    updatedItemlist[0].treatment_plans = updatedTreatmentPlans;

    setItemlist2(updatedItemlist);
  };

  const handleEditPro = () => {
    setEditPro(true);
  };

  const showModal = () => {
    setSelectedPatientId(props.data.id);
    setIsModalVisible1(true);
  };

  const handleUploadStart = () => {
    setUploadKey(Math.random());
  };

  const getAllInputValues = () => {
    const updatedRecords = data.map((record) => ({
      ...record,
      ...editState[record.key], // Override with the edited values
    }));

    return updatedRecords;
  };

  const handleVisible = async (record, visibility) => {
    try {
      await controller.visibleTreatmentPlans(record.key, {
        visible_to_patient: visibility,
      });

      const updatedTableData = tableData.map((row) => {
        if (row.key === record.key) {
          return { ...row, visible: visibility };
        }
        return row;
      });

      setTableData(updatedTableData);

      notification.success({
        message: "Success",
        description: `Visibility for ${record.treatment} updated successfully.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.error("Error updating visibility:", error);
      notification.error({
        message: "Error",
        description: `Error updating visibility for ${record.treatment}.`,
        placement: "bottomRight",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [form] = Form.useForm();

  // Check if educational_content exists and has at least one item with a video_file array
  const contentExists =
    edulist &&
    edulist.educational_content &&
    edulist.educational_content.length > 0 &&
    edulist.educational_content.some((item) => item.video_file && item.video_file.length > 0);

  // Get the total number of video files if educational_content exists
  const totalPages = contentExists
    ? edulist.educational_content.reduce((total, item) => total + (item.video_file ? item.video_file.length : 0), 0)
    : 0;

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle "Previous" button click
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEditModal = (id) => {
    setModalEdit(true);
    setEduId(id);
  };

  const handleEditModal2 = (id) => {
    setModalEdit2(true);
    setEduId(id);
  };

  // This is for adding a procedure for a new treatment
  const handleAddNewTreatmentProcedure = () => {
    setNewTreatmentProcedure(true);
  };

  const handleAddExistingPro = (id, record) => {
    if (record.treatment) {
      setIsModalVisible3(true);
      setSelectModal(true);
      setTreatmentId(id);
      setTreatmentName(record.name);
    } else {
      notification.error({
        message: "Error",
        description: "Please Fill Treatment Name.",
        placement: "bottomRight",
      });
    }
  };

  const handleAddExistingPro2 = (id, record) => {
    if (record.treatment) {
      setIsModalVisible5(true);
      setSelectModal(true);
      setTreatmentId(id);
      setTreatmentName(record.name);
    } else {
      notification.error({
        message: "Error",
        description: "Please Fill Treatment Name .",
        placement: "bottomRight",
      });
    }
  };

  const updateEducational2 = async () => {
    try {
      const formData = new FormData();
      if (desc2) {
        formData.append("description", desc2);
      }

      await controller.UpdateEducational2(eduid, formData);
      handleCancel();
      handleReadEdu();
      handleEduList();
      setModalEdit(false);
      message.success("Educational Content updated successfully!");
    } catch (error) {
      console.error("Error updating educational:", error);
      message.error("Failed to update educational Content. Please try again.");
    }
  };

  const updateEducational3 = async () => {
    try {
      const formData = new FormData();
      if (desc2) {
        formData.append("description", desc2);
      }

      if (eduvalue && eduvalue.length > 0) {
        eduvalue.forEach((file) => {
          formData.append("video_file", file);
        });
      }

      await controller.UpdateEducational2(eduid, formData);
      handleCancel();
      handleReadEdu();
      handleEduList();
      setModalEdit2(false);
      message.success("Educational Content updated successfully!");
    } catch (error) {
      console.error("Error updating educational:", error);
      message.error("Failed to update educational Content. Please try again.");
    }
  };

  const onBack = () => {
    props.setCurrentStep(1);
  };

  const getImageUrl = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file.image_path || URL.createObjectURL(file);
  };

  const onFinish = (values) => {
    console.log("Form values: ", values);
  };

  const handleTextAreaChange = (e) => {
    setNote(e.target.value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDesc("");
    setEduValue([]);
    setNewTab(false);
    setDesc2("");
    setModalEdit2(false);
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
    setSelectedRowKeys2([]);
    setSearch("");
  };

  const handleCancel4 = () => {
    setIsModalVisible5(false);
    setSelectedRowKeys2([]);
    setSearch("");
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setText("");
    setUrl("");
    setName("");
    setVideoFile([]);
    form.resetFields();
  };

  const handleOpen = (record) => {
    setIsModalVisible(true);
    setId(record.key);
    handleReadEdu();
    setCurrentPage(0);
    handleEduList();
  };

  const handleOpen2 = (record) => {
    setIsModalVisible2(true);
  };

  const handleExpandedInputChange = (value, key, field) => {
    // value will be item.id here
    const updatedData = { ...expandedRowInputValues[key], [field]: value }; // Store item.id in 'procedure_code'
    setExpandedRowInputValues({
      ...expandedRowInputValues,
      [key]: updatedData,
    });

    // Send updatedData to the server, and it will contain the correct item.id
  };

  useEffect(() => {
    if (!isExpanded) {
      setNote("");
      setImageFile([]);
    }
  }, [isExpanded]);

  const Priority = ({ loading, initialPriority, onUpdate, record }) => {
    const [count, setCount] = useState(Number(initialPriority));

    const increasePriority = async () => {
      const newPriority = Number(count) + 1;
      setCount(newPriority);
      onUpdate(newPriority, record);
    };

    const decreasePriority = async () => {
      const newPriority = Math.max(Number(count) - 1, 0);
      setCount(newPriority);
      onUpdate(newPriority, record);
    };

    useEffect(() => {
      setCount(initialPriority);
    }, [initialPriority]);

    return (
      <div className="div-prority">
        <Button type="text" icon={<img src={down2} alt="" />} onClick={decreasePriority} />
        {loading ? <Spin size="small" /> : <p className="p-fontSize"> {count} </p>}
        <Button type="text" icon={<img src={up2} alt="" />} onClick={increasePriority} />
      </div>
    );
  };

  const handleDelete2 = async (imageId, index) => {
    try {
      if (imageId) {
        await controller.removeImages(imageId);
        setImageFile((prevFiles) => prevFiles.filter((file) => file.id !== imageId));
      } else {
        setImageFile((prevFiles) => {
          const updatedFiles = prevFiles.filter((file, i) => i !== index);

          return updatedFiles;
        });
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // Use `onRemove` for consistent delete behavior
  const handleRemove = async (file) => {
    const fileIndex = imageFile.findIndex((item) => item.name === file.name);
    const imageId = imageFile[fileIndex] ? imageFile[fileIndex].id : null;
    await handleDelete2(imageId, fileIndex);
  };

  useEffect(() => {
    console.log("Current image files in state:", imageFile);
    // Any other operations that depend on updated imageFile
  }, [imageFile]);

  const handleUploadChange = (info) => {
    setImageFile((prevFiles) => {
      // Filter out files that do not have `originFileObj`
      const newFiles = info.fileList.map((file) => file.originFileObj).filter((file) => file !== undefined); // Ensure only valid file objects are included

      // Combine new files with previous files, avoiding duplicates based on name and lastModified
      const combinedFiles = [
        ...prevFiles,
        ...newFiles.filter(
          (newFile) => !prevFiles.some((prevFile) => prevFile.name === newFile.name && prevFile.lastModified === newFile.lastModified)
        ),
      ];

      return combinedFiles;
    });
  };

  const handleUploadChange2 = (info) => {
    setVideoFile((prevFiles) => {
      const newFiles = info.fileList.map((file) => file.originFileObj);

      const combinedFiles = [
        ...prevFiles,
        ...newFiles.filter(
          (newFile) => !prevFiles.some((prevFile) => prevFile.name === newFile.name && prevFile.lastModified === newFile.lastModified)
        ),
      ];

      if (newFiles.length > 0) {
        notification.success({
          message: "Success",
          description: `Your file has been uploaded successfully.`,
          placement: "bottomRight",
        });
      }

      return combinedFiles;
    });
  };

  const handleReadData = async () => {
    var id = localStorage.getItem("patient_id")
      ? localStorage.getItem("patient_id")
      : localStorage.getItem("modalSelectId")
      ? localStorage.getItem("modalSelectId")
      : localStorage.getItem("newPatientId");
    try {
      const response = await controller.getDoctorNote(id);

      if (response.status < 250) {
        const jsonData = await response.json;

        if (jsonData.results && jsonData.results.length > 0) {
          // Extract the IDs
          const ids = jsonData.results.map((result) => result.id);

          const largestId = Math.max(...ids);
          const largestIdData = jsonData.results.find((result) => result.id === largestId);

          setData(largestIdData);
          setNote(largestIdData.note);

          if (largestIdData.image_files && largestIdData.image_files.length > 0) {
            const imageData = largestIdData.image_files.map((file) => ({
              path: file.image_path,
              id: file.id,
              name: file.file_name,
            }));
            setImageFile(imageData);
          } else {
            console.error("No image file found in the response");
            setImageFile([]);
          }

          const treatmentIDs = largestIdData.treatment_plans.map((plan) => plan.id);
          setSelectedTreatmentIds(treatmentIDs);
          setSelectedRowKeys(treatmentIDs.map((id) => id.toString()));
        } else {
          console.error("No note found in the response");
          setSave(true);
          setImageFile([]);
        }
      }
    } catch (e) {
      console.error("Error fetching the note:", e);
      // Optionally handle the error, e.g., display a notification
      setImageFile([]); // Ensure imageFile is an empty array if an error occurs
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleReadData();
    }, 1000); // Adjust the delay time in milliseconds as needed

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  useEffect(() => {
    setSelectedRowKeys(selectedTreatmentIds.map((id) => id.toString()));
  }, [selectedTreatmentIds]);

  const handleReadEdu = async () => {
    try {
      const response = await controller.getEducatinal(0);
      if (response.status < 250) {
        const data = await response.json;
        const results = data;
        setEdu(results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleButtonClick = () => {
    // Get patient_id from localStorage
    const patientId = localStorage.getItem("patient_id");

    if (patientId) {
      // Determine the base URL based on the current environment
      const currentUrl = window.location.href;
      const baseUrl = currentUrl.includes("staging") ? "https://fe-staging.smilepass.com/" : "https://app.smilepass.com/";

      // Construct the full URL with the determined base and patient_id
      const url = `${baseUrl}#/new/treatment-plan/?patient_id=${patientId}`;

      // Open the URL in a new tab
      window.open(url, "_blank");
    } else {
      console.error("Patient ID not found in localStorage");
    }
  };

  const handleReadData2 = async () => {
    var id = localStorage.getItem("patient_id")
      ? localStorage.getItem("patient_id")
      : localStorage.getItem("modalSelectId")
      ? localStorage.getItem("modalSelectId")
      : localStorage.getItem("newPatientId");
    try {
      setIsGettingTreatmentPlans(true);
      const response = await controller.getTreatmentPlans2(
        "",
        searchQuery2,
        current,
        startDates,
        endDates,
        selectedprocedure,
        id,
        "non-pms-only"
      );

      console.log("Reading Data 2", response.results);

      if (response.status < 250) {
        const data = await response.json;
        // console.log("Fetched data:", data.results); // Check if data.results is correct
        setItemList(data.results); // Set state with fetched data
      } else {
        console.log("Response status not OK:", response.status);
      }
      setIsGettingTreatmentPlans(false);
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  // const history = useHistory();

  useEffect(() => {
    if (itemlist && Array.isArray(itemlist)) {
      const data = itemlist.filter(
        (user) => user.treatment_plans && user.treatment_plans.some((plan) => selectedTreatmentIds.includes(plan.id))
      );
      setFilteredData2(data);
    }
  }, [itemlist, selectedTreatmentIds]);

  const handlePreviewClick = () => {
    // Save data to localStorage
    localStorage.setItem("filteredData2", JSON.stringify(filteredData2));
    localStorage.setItem("imageFile", JSON.stringify(imageFile)); // Convert to JSON string

    // Open the new tab with the full URL
    const baseUrl = window.location.origin; // Get the base URL of your app
    window.open(`${baseUrl}/#/preview`, "_blank"); // Open the new tab with the correct URL
  };

  useEffect(() => {
    localStorage.removeItem("filteredData2");
    localStorage.removeItem("imageFile");
  }, []);

  const handleReadPmsData = async () => {
    var id = localStorage.getItem("patient_id")
      ? localStorage.getItem("patient_id")
      : localStorage.getItem("modalSelectId")
      ? localStorage.getItem("modalSelectId")
      : localStorage.getItem("newPatientId");
    try {
      const response = await controller.getProceduresPms(id, search);

      if (response.status < 250) {
        const data = await response.json;

        setPms(data.results); // Set state with fetched data
      } else {
        console.log("Response status not OK:", response.status);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const handleEduList = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await controller.getEduByPatient(id);

      if (response.status < 250) {
        const data = await response.json;
        setEduList(data); // Set state with fetched data
      } else {
        console.log("Response status not OK:", response.status);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setLoading(false); // Ensure loading is false after operation completes
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleReadData2();
    }, 1000); // Adjust the delay time in milliseconds as needed

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      handleReadPmsData();
    }, 2000);

    setDebounceTimeout(newTimeout);

    return () => clearTimeout(newTimeout);
  }, [search]);

  useEffect(() => {
    // Check if id exists and is different from the previous id
    if (id && id !== prevIdRef.current) {
      const timeoutId = setTimeout(() => {
        handleEduList();
      }, 500);

      prevIdRef.current = id;

      return () => clearTimeout(timeoutId);
    }
  }, [id]);

  useEffect(() => {
    processTreatmentPlans(); // This will log the updated itemlist when it changes
  }, [itemlist]);

  const handleReadProcuders = async () => {
    try {
      const response = await controller.getProcedure(0, "");
      if (response.status < 250) {
        const data = await response.json;
        const results = data;
        setProcedures(results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleReadProcuders();
  }, []);

  useEffect(() => {
    handleReadEdu();
  }, []);

  const handleSuccessAddToServer = () => {
    props.updateData();
  };

  const processTreatmentPlans = () => {
    console.log("Itemlist received:", itemlist);

    // Check if itemlist has at least one object and then access its treatment_plans
    if (
      itemlist.length > 0 &&
      itemlist[0].treatment_plans &&
      Array.isArray(itemlist[0].treatment_plans) &&
      itemlist[0].treatment_plans.length > 0
    ) {
      const treatmentPlans = itemlist[0].treatment_plans.map((plan) => ({
        key: plan.id.toString(), // Assuming each plan has a unique 'id'
        treatment: plan.name || "-", // Assuming each plan has a 'name' field
        estimatedCost: plan.estimated_cost || "", // Optional field
        insuranceCoverage: plan.insurance_coverage || "", // Optional field
        tooth_from: plan.tooth_from || "-", // Optional field
        priority: plan.priority || 0, // Optional field
        procedure: plan.procedure ? plan.procedure.name : "-", // Optional nested field
        discount: plan.discount || "0", // Optional field
        patientResponsibility: plan.patient_responsibility || "-",
        provider: plan.provider || "-",
        surface: plan.surface || "-",
      }));

      // Set the transformed treatment plans to your table
      setTableData(treatmentPlans);

      // Initialize the edit state based on the keys of the treatment plans
      const initialEditState = {};
      treatmentPlans.forEach((row) => {
        initialEditState[row.key] = false;
      });
      setEditState(initialEditState);
    } else {
      console.error("No treatment plans available. Debugging info:");
      console.error("Itemlist:", itemlist);
      console.error("Treatment Plans:", itemlist[0] ? itemlist[0].treatment_plans : "None");
      setTableData([]);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered");
    if (itemlist) {
      processTreatmentPlans();
    }
  }, [itemlist]);

  const tableRef = useRef(null); // Reference for the table container

  // Handle input change for editable fields
  const addTreatmentPlan = () => {
    const newRow = {
      key: Date.now().toString(),
      treatment: "",
      estimatedCost: "",
      insuranceCoverage: "",
      notes: "",
      priority: "",
      procedure: "",
      isNew: true, // Flag new rows
    };

    setDisableAdd(true);
    setNew1(true);

    // Append the new row to the end of the tableData array
    setTableData((prevTableData) => [...prevTableData, newRow]);
    setEditState((prevEditState) => ({ ...prevEditState, [newRow.key]: true }));
    setExpandedRowKeys((prevExpandedRowKeys) => [...prevExpandedRowKeys, newRow.key]);

    // Auto-scroll to the bottom of the table
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.scrollTo({
          top: tableRef.current.scrollHeight,
          behavior: "smooth", // Smooth scrolling
        });
      }
    }, 500);
  };

  // Handle input change for editable fields
  const handleInputChange = (e, key, field) => {
    const newValue = e.target.value;
    const newData = [...tableData];
    const index = newData.findIndex((item) => item.key === key);
    if (index > -1) {
      const item = newData[index];
      item[field] = newValue; // Update the specific field
      setTableData(newData);
    }
  };

  const handleNoteChange = (e, record) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === record.key) {
        return { ...row, tooth_from: e.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleNameChange = (e, record) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === record.key) {
        return { ...row, treatment: e.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleEstimateChange = (e, record) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === record.key) {
        return { ...row, estimatedCost: e.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleInsuranceChange = (e, record) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === record.key) {
        return { ...row, insuranceCoverage: e.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleDiscountChange = (e, record) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === record.key) {
        return { ...row, insuranceCoverage: e.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleEdit = (record) => {
    const updatedEditState = { ...edit, [record.key]: !edit[record.key] };
    setEdit(updatedEditState);
  };

  const cancelEdit = async (record) => {
    await saveNotesToServer(record);
    const updatedEditState = { ...edit, [record.key]: !edit[record.key] };
    setEdit(updatedEditState);
  };

  const saveNotesToServer = async (record) => {
    try {
      await controller.UpdateTreatmentPlans(record.key, {
        tooth_from: record.tooth_from,
        name: record.treatment,
        estimated_cost: record.estimatedCost,
        insurance_coverage: record.insuranceCoverage,
      });
      props.updateDataUpdatePriority();
      setDisableAdd(false);
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  };

  const savePriorityToServer = async (record) => {
    setUpdatingPriority(record.key);
    try {
      await controller.UpdateTreatmentPlans(record.key, {
        priority: record.priority,
      });
      setTableData((prevTableData) => prevTableData.map((row) => (row.key === record.key ? { ...row, priority: record.priority } : row)));
      props.updateDataUpdatePriority();
    } catch (error) {
      console.error("Error updating priority:", error);
    }
    setTimeout(() => {
      setUpdatingPriority(null);
    }, 800);
  };

  // const saveTreatmentPlanToServer = async (record, recordKey) => {
  //     try {
  //         await controller.createTreatmentPlans2({
  //             name: record.treatment,
  //             patient: props.data.id,
  //             tooth_from: record.tooth_from,
  //         });

  //         // Update table state to reflect changes after successful API call
  //         setTableData(prevTableData =>
  //             prevTableData.map(row =>
  //                 row.key === record.key ? { ...row, ...record } : row
  //             )
  //         );

  //         processTreatmentPlans();

  //         message.success("Treatment plan create successfully!");
  //         window.location.reload();

  //     } catch (error) {
  //         console.error("Error saving treatment plan:", error);

  //         // Display error message
  //         message.error("Failed to create treatment plan. Please try again.");
  //     }

  // };

  const saveTreatmentPlanToServer = async (record, recordKey) => {
    const expandedRowData = expandedRowInputValues[recordKey];

    if (!record.treatment) {
      notification.error({
        message: "Error",
        description: "Please fill Treatment Name.",
        placement: "bottomRight",
      });
      return;
    }
    // if ((!selectedRowKeys2 || selectedRowKeys2.length === 0) && (!expandedRowData || !expandedRowData.procedure_code)) {
    //   notification.error({
    //     message: "Error",
    //     description: "Please select Procedure Code.",
    //     placement: "bottomRight",
    //   });
    //   return;
    // }

    const { tooth_from, discount, insuranceCoverage, estimatedCost } = tableData.find((item) => item.key === recordKey);

    // Check if expandedRowData exists before accessing estimated_cost
    // if ((!selectedRowKeys2 || selectedRowKeys2.length === 0) && (!expandedRowData || !expandedRowData.estimated_cost || !estimatedCost)) {
    if (!estimatedCost && !itemize[record.key]) {
      notification.error({
        message: "Error",
        description: "Please fill Estimated Cost.",
        placement: "bottomRight",
      });
      return;
    }

    const treatmentProcedureData = {};

    // Check if the itemized checkbox is marked, if so, only the treatment and tooth data must be send (with procedure code or without procedure code)

    if (!itemize[record.key]) {
      treatmentProcedureData.tooth_from = tooth_from;
      treatmentProcedureData.discount = discount;
      treatmentProcedureData.insurance_coverage = insuranceCoverage;
      treatmentProcedureData.estimated_cost = estimatedCost;
    }

    if (expandedRowData) {
      if (expandedRowData.procedure_code) treatmentProcedureData.procedure_code = expandedRowData.procedure_code;
      if (expandedRowData.tooth_quad) treatmentProcedureData.tooth = expandedRowData.tooth_quad;
      if (expandedRowData.discount) treatmentProcedureData.discount = expandedRowData.discount;
      if (expandedRowData.insurance_estimate) treatmentProcedureData.insurance_estimate = expandedRowData.insurance_estimate;
      if (expandedRowData.estimated_cost) treatmentProcedureData.estimated_cost = expandedRowData.estimated_cost;
    }

    if (selectedRowKeys2 && selectedRowKeys2.length > 0) {
      treatmentProcedureData.treatment_procedure_ids = selectedRowKeys2;
    }

    if (Object.keys(treatmentProcedureData).length > 0 || selectedRowKeys2.length > 0) {
      setLoading(true); // Set loading to true
      try {
        const dataToSend = {
          name: record.treatment,
          patient: localStorage.getItem("patient_id")
            ? localStorage.getItem("patient_id")
            : localStorage.getItem("modalSelectId")
            ? localStorage.getItem("modalSelectId")
            : localStorage.getItem("newPatientId"),
        };
        let response;

        if (record.isNew && itemize[record.key]) {
          dataToSend.treatment_procedure_data = treatmentProcedureData;
          dataToSend.tooth_from = record.tooth_from;

          response = await controller.createTreatmentPlanAndProcedure(dataToSend);
        } else {
          dataToSend.discount = discount;
          dataToSend.tooth_from = tooth_from;
          dataToSend.estimated_cost = estimatedCost;
          dataToSend.insurance_coverage = insuranceCoverage;

          response = await controller.createTreatmentPlans(dataToSend);
        }

        if (response.status < 250) {
          message.success("Treatment plan created successfully!");
        }

        // Handling procedures after creating treatment with procedure
        const treatmentProcedures = treatmentProcedure.filter((procedure) => procedure !== record.key);

        setTableData((prevTableData) => prevTableData.map((row) => (row.key === record.key ? { ...row, ...record } : row)));
        processTreatmentPlans();
        setDisableAdd(false);
        handleReadData2();
        setNew1(false);
        setIsModalVisible3(false);
        setSelectedRowKeys2([]);
        setTreatmentProcedure((prevState) => [...prevState, treatmentProcedures]);
        setNewTreatmentProcedure(false);

        setExpandedRowInputValues((prevValues) => ({
          ...prevValues,
          [recordKey]: {
            procedure_code: "",
            tooth_quad: "",
            discount: "",
            insurance_estimate: "",
            estimated_cost: "",
          },
        }));

        props.handleReadData2();
        processTreatmentPlans();
      } catch (error) {
        console.error("Error saving treatment plan:", error);
      } finally {
        setLoading(false); // Reset loading to false after completion
      }
    } else {
      console.error("No treatment procedure data or selectedRowKeys2 to send.");
    }
  };

  const postProcedure = async (record, recordKey) => {
    const treatmentId = Number(recordKey);
    const expandedRowData = expandedRowInputValues[treatmentId];

    if (!expandedRowData) {
      notification.error({
        message: "Error",
        description: "Please Fill Inputs.",
        placement: "bottomRight",
      });
      return;
    }

    if (!expandedRowData.procedure_code) {
      notification.error({
        message: "Error",
        description: "Please select Procedure Code",
        placement: "bottomRight",
      });
      return;
    }

    // if (!expandedRowData.insurance_estimate) {
    //     notification.error({
    //         message: 'Error',
    //         description: 'Please Fill Insurance Estimate',
    //         placement: 'bottomRight',
    //     });
    //     return;
    // }

    if (!expandedRowData.estimated_cost) {
      notification.error({
        message: "Error",
        description: "Please Fill Estimated Cost",
        placement: "bottomRight",
      });
      return;
    }

    const procedureData = {
      procedure_code: expandedRowData.procedure_code || "",
      treatment: treatmentId,
      insurance_estimate: expandedRowData.insurance_estimate || "0",
      estimated_cost: expandedRowData.estimated_cost || "",
    };

    if (expandedRowData.tooth_quad) {
      procedureData.tooth = expandedRowData.tooth_quad;
    }
    if (expandedRowData.discount) {
      procedureData.discount = expandedRowData.discount;
    }

    try {
      setLoading(true);
      await controller.createProcedures(procedureData);

      setTableData((prevTableData) =>
        prevTableData.map((row) =>
          row.key === record.key
            ? {
                ...row,
                procedure_code: expandedRowData.procedure_code,
                tooth_quad: expandedRowData.tooth_quad,
                discount: expandedRowData.discount,
                insurance_estimate: expandedRowData.insurance_estimate,
                estimated_cost: expandedRowData.estimated_cost,
              }
            : row
        )
      );
      // Handling procedures after creating treatment with procedure

      message.success("Procedure created successfully!");
      handleReadData2();
      processTreatmentPlans();
      setDisableAdd(false);
      // setTreatmentProcedure(prevState => [...prevState, treatmentProcedures]);
      setNewTreatmentProcedure(false);

      setExpandedRowInputValues((prevValues) => ({
        ...prevValues,
        [treatmentId]: {
          procedure_code: "",
          tooth_quad: "",
          discount: "",
          insurance_estimate: "",
          estimated_cost: "",
        },
      }));
      setLoading(false);
    } catch (error) {
      setNewTreatmentProcedure(false);

      console.error("Error saving procedure:", error);

      message.error("Failed to create procedure. Please try again.");
    } finally {
      const treatmentProcedures = treatmentProcedure.filter((procedure) => procedure !== record.key);
      setTreatmentProcedure((prevState) => [...prevState, treatmentProcedures]);
    }
  };

  const postEducational = async () => {
    const formData = new FormData();

    try {
      if (videoFile && videoFile.length > 0) {
        videoFile.forEach((file) => {
          formData.append("video", file);
        });
      }
      formData.append("name", name);
      formData.append("link", url);
      formData.append("description", text);
      formData.append("redirect", isChecked);

      await controller.createEducational(formData);

      handleReadEdu();
      message.success("Educational created successfully!");
      handleCancel2();
    } catch (error) {
      console.error("Error saving educational:", error);
      message.error("Failed to create educational. Please try again.");
    }
  };

  const postExistingPro = async () => {
    const formData = new FormData();

    setLoading(true); // Set loading to true at the start
    try {
      if (selectedRowKeys2 && selectedRowKeys2.length > 0) {
        selectedRowKeys2.forEach((id) => {
          formData.append("treatment_procedure_ids", id);
        });
      }
      formData.append("treatment_id", treatmentId);

      await controller.postExistingPro(formData);

      handleReadData2();
      setIsModalVisible3(false);
      setSearch("");

      message.success("Procedures created successfully!");
      handleCancel3();
    } catch (error) {
      console.error("Error saving procedures:", error);
      message.error("Failed to create Procedures. Please try again.");
    } finally {
      setLoading(false); // Reset loading to false after completion
    }
  };

  const handleDelete = async (record) => {
    try {
      await controller.RemoveTreatmentPlans(record.key);
      const updatedTableData = tableData.filter((row) => row.key !== record.key);
      setTableData(updatedTableData);

      // Update selectedRowKeys
      setSelectedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.key));

      // Update selectedTreatmentIds
      setSelectedTreatmentIds((prevIds) => {
        const updatedIds = prevIds.filter((id) => id !== parseInt(record.key, 10));
        return updatedIds;
      });

      handleReadData2();
      processTreatmentPlans();
      setDisableAdd(false);
    } catch (error) {
      console.error("Error deleting treatment plan:", error);
    }
  };

  const deleteProcedures = async (id) => {
    try {
      await controller.RemoveProcedures(id);
      const updatedTableData = tableData.filter((row) => row.key !== id);
      setTableData(updatedTableData);
      handleReadData2();
      processTreatmentPlans();
      setDisableAdd(false);
    } catch (error) {
      console.error("Error deleting treatment plan:", error);
    }
  };

  const renderEditCellToothFrom = (record) => {
    const isEditing = edit[record.key];
    return (
      <span>
        {isEditing ? (
          <Input.TextArea
            style={{ border: "1px solid #C9C1F1" }}
            value={record.tooth_from}
            onChange={(e) => handleNoteChange(e, record)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        ) : (
          <span>{record.tooth_from}</span>
        )}
      </span>
    );
  };

  const renderEditCellTreatment = (record) => {
    const isEditing = edit[record.key];
    return (
      <span>
        {isEditing ? (
          <Input.TextArea
            style={{ border: "1px solid #C9C1F1" }}
            value={record.treatment}
            onChange={(e) => handleNameChange(e, record)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        ) : (
          <span>{record.treatment}</span>
        )}
      </span>
    );
  };

  const renderEditCellEstimateCost = (record) => {
    const isEditing = edit[record.key];
    return (
      <span>
        {isEditing ? (
          <Input.TextArea
            style={{ border: "1px solid #C9C1F1" }}
            value={record.estimatedCost}
            onChange={(e) => handleEstimateChange(e, record)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        ) : (
          <span>{record.estimatedCost}</span>
        )}
      </span>
    );
  };

  const renderEditCellInsuranceCoverage = (record) => {
    const isEditing = edit[record.key];
    return (
      <span>
        {isEditing ? (
          <Input.TextArea
            style={{ border: "1px solid #C9C1F1" }}
            value={record.insuranceCoverage}
            onChange={(e) => handleInsuranceChange(e, record)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        ) : (
          <span>{record.insuranceCoverage}</span>
        )}
      </span>
    );
  };

  const renderEditCellDiscount = (record) => {
    const isEditing = edit[record.key];
    return (
      <span>
        {isEditing ? (
          <Input.TextArea
            style={{ border: "1px solid #C9C1F1" }}
            value={record.discount}
            onChange={(e) => handleDiscountChange(e, record)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        ) : (
          <span>{record.discount}</span>
        )}
      </span>
    );
  };

  // const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
  //     setSelectedRowKeys(newSelectedRowKeys);
  //     const ids = newSelectedRows.map(row => row.key);
  //     setSelectedTreatmentIds(ids);
  //     console.log('Selected Treatment IDs:', ids);
  // };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      const ids = newSelectedRowKeys.map((key) => parseInt(key, 10));
      setSelectedTreatmentIds(ids);
    },
  };

  // const handleApprove = async () => {
  //     if (selectedTreatmentIds.length === 0) {
  //         notification.error({
  //             message: 'Error',
  //             description: 'Please select at least one treatment plan.',
  //             placement: 'bottomRight',
  //         });
  //         return;
  //     }

  //     setIsLoading(true);
  //     try {
  //         const formData = new FormData();
  //         for (var i in selectedTreatmentIds) {
  //             formData.append('treatment_plans', selectedTreatmentIds[i]);
  //         }
  //         formData.append('note', note);
  //         formData.append("patient", props.data.id);
  //         imageFile.forEach((file, index) => {
  //             if (file instanceof File || file instanceof Blob) {
  //                 formData.append(`image_files`, file);
  //             }
  //         });
  //         const response = await controller.postNoteAndImage(formData, 'sent');
  //         if (response.status < 250) {
  //             notification.success({
  //                 message: 'Success',
  //                 description: 'Your Files Successfully Sent.',
  //                 placement: 'bottomRight',
  //             });
  //             setIsExpanded(false);
  //             setIsLoading(false);
  //             props.setCurrentStep(1);
  //         }
  //     } catch (error) {
  //         console.error("Error occurred during upload:", error);
  //         setIsLoading(false);
  //     }
  // };

  const hasVideo =
    edulist &&
    edulist.educational_content &&
    edulist.educational_content[0] &&
    edulist.educational_content[0].video_file &&
    edulist.educational_content[0].video_file.length > 0;

  // Get the current video details
  const currentVideo = hasVideo ? edulist.educational_content[0].video_file[currentPage] || {} : {};
  const videoLink = currentVideo.link;

  // Determine video type
  const isYouTube = videoLink && videoLink.includes("youtube.com");
  const isSpearEdu = videoLink && videoLink.includes("spearedu.co");
  const isPatientApi = videoLink && videoLink.includes("patient-api.speareducation.com");

  // Handle redirection logic when currentVideo changes
  useEffect(() => {
    if (currentVideo.redirect && videoLink) {
      setNewTab(true);
      setIsModalVisible(false);
    }
  }, [currentVideo.redirect, videoLink]);

  // Open new tab if redirection is required
  useEffect(() => {
    if (newTab && videoLink && currentVideo.redirect) {
      window.open(videoLink, "_blank");
      setNewTab(false);
    }
  }, [newTab, videoLink, currentVideo.redirect]);

  // Reset newTab when the modal is opened
  useEffect(() => {
    if (isModalVisible) {
      setNewTab(false);
    }
  }, [isModalVisible]);

  const handleApprove2 = async (status) => {
    if (selectedTreatmentIds.length === 0) {
      notification.error({
        message: "Error",
        description: "Please select at least one treatment plan.",
        placement: "bottomRight",
      });
      return;
    }

    if (note === "") {
      notification.error({
        message: "Error",
        description: "Please Write Note",
        placement: "bottomRight",
      });
      return;
    }

    try {
      if (save) {
        setIsLoading1(true);
        const formData = new FormData();
        for (var i in selectedTreatmentIds) {
          formData.append("treatment_plans", selectedTreatmentIds[i]);
        }
        formData.append("note", note);
        formData.append(
          "patient",
          props.data && props.data.id // Check if props.data is truthy before accessing id
            ? props.data.id
            : localStorage.getItem("modalSelectId")
            ? localStorage.getItem("modalSelectId")
            : localStorage.getItem("newPatientId")
        );
        imageFile.forEach((file, index) => {
          if (file instanceof File || file instanceof Blob) {
            formData.append(`image_files`, file);
          }
        });
        const response = await controller.postNoteAndImage(formData, status);
        if (response.status < 250) {
          notification.success({
            message: "Success",
            description: "Data Updated Successfully.",
            placement: "bottomRight",
          });
          setIsExpanded(false);
          setIsLoading1(false);
          // props.setCurrentStep(1);
          handleReadData();
        }
      } else {
        setIsLoading(true);
        const formData = new FormData();
        for (var j in selectedTreatmentIds) {
          formData.append("treatment_plans", selectedTreatmentIds[j]);
        }
        formData.append("note", note);
        // formData.append("patient", props.data.id);
        imageFile.forEach((file, index) => {
          if (file instanceof File || file instanceof Blob) {
            formData.append(`image_files`, file);
          }
        });
        formData.append("status", status);
        const response = await controller.UpdateDoctorNote(formData, data.id);
        if (response.status < 250) {
          await response.json;

          notification.success({
            message: "Success",
            description: "Data Updated Successfully.",
            placement: "bottomRight",
          });
          setIsExpanded(false);
          setIsLoading1(false);
          handleReadData();
          // props.setCurrentStep(1);
          // if (status == "sent") {
          //     window.location.reload();
          // }
        }
        setIsLoading1(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error occurred during operation:", error);
      setIsLoading1(false);
      setIsLoading(false);
    }
  };

  const updateEducational = async () => {
    try {
      const formData = new FormData();
      formData.append("description", desc);

      formData.append("treatment", id);

      eduvalue.forEach((value) => {
        const pk = parseInt(value, 10);

        if (!isNaN(pk)) {
          formData.append("video_file", pk);
        } else {
          console.error(`Invalid PK value: ${value}`);
        }
      });

      await controller.UpdateEducational(formData);
      handleCancel();
      handleReadEdu();
      message.success("Educational Content updated successfully!");
    } catch (error) {
      console.error("Error updating educational:", error);
      message.error("Failed to update educational Content. Please try again.");
    }
  };

  const handleSaveTreatment = async (record) => {
    try {
      await saveTreatmentPlanToServer(record, record.key);
      setItemize((prev) => ({ ...prev, [record.key]: false }));
    } catch (err) {
      console.error("Error saving treatment: ", err);
    }
  };

  const handleExpandTable = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
    }
  };

  // تعریف عرض ستون‌های اضافی
  const EXTRA_COLUMNS_WIDTH = 80; // عرض هر ستون اضافی (Selection و Expand)

  // تعریف عرض ستون‌ها به صورت ثابت
  const columnWidths = {
    priority: 80,
    treatment: 220,
    tooth: 120,
    procedure_code_description: 300,
    estimated_cost: 150,
    insurance_estimate: 120,
    discount: 100,
    patient_responsibility: 150,
    actions: 100,
    action: 130,
  };

  const columns3 = [
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: columnWidths.priority,
      render: function (priority, record) {
        return (
          <Priority
            loading={record.key !== updatingPriority ? false : true}
            initialPriority={priority}
            onUpdate={(newPriority, record) => {
              savePriorityToServer({ ...record, priority: newPriority });
            }}
            record={record}
          />
        );
      },
    },
    {
      title: "Treatment",
      dataIndex: "treatment",
      key: "treatment",
      width: columnWidths.treatment,
      render: function (text, record) {
        if (editState[record.key]) {
          return (
            <Input
              value={record.treatment}
              onChange={function (e) {
                handleInputChange(e, record.key, "treatment");
              }}
              placeholder="Treatment"
            />
          );
        } else if (edit[record.key]) {
          return renderEditCellTreatment(record);
        } else {
          return text;
        }
      },
    },
    // سایر ستون‌های columns3
    {
      title: "Tooth/Quad",
      dataIndex: "tooth_from",
      key: "tooth_from",
      width: columnWidths.tooth,
      render: function (text, record) {
        if (editState[record.key]) {
          return (
            <Input
              value={record.tooth_from}
              onChange={function (e) {
                handleInputChange(e, record.key, "tooth_from");
              }}
              placeholder="Tooth/Quad"
            />
          );
        } else if (edit[record.key]) {
          return renderEditCellToothFrom(record);
        } else {
          return text;
        }
      },
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimatedCost",
      key: "estimatedCost",
      width: columnWidths.estimated_cost,
      render: function (text, record) {
        if (record.isNew) {
          if (itemize[record.key]) {
            return null;
          }

          return (
            <Input
              placeholder="Cost"
              style={{ width: "100%" }}
              value={record.estimatedCost || ""}
              onChange={(e) => handleInputChange(e, record.key, "estimatedCost")}
            />
          );
        } else if (edit[record.key]) {
          return renderEditCellEstimateCost(record);
        } else {
          return text ? "$" + text : "-";
        }
      },
    },
    {
      title: "Insurance Estimate",
      dataIndex: "insuranceCoverage",
      key: "insuranceCoverage",
      width: columnWidths.insurance_estimate,
      render: function (text, record) {
        if (record.isNew) {
          if (itemize[record.key]) {
            return null;
          }

          return (
            <Input
              placeholder="Insurance"
              style={{ width: "100%" }}
              value={record.insuranceCoverage || ""}
              onChange={(e) => handleInputChange(e, record.key, "insuranceCoverage")}
            />
          );
        } else if (edit[record.key]) {
          return renderEditCellInsuranceCoverage(record);
        } else {
          return text ? "$" + text : "-";
        }
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: columnWidths.discount,
      render: function (text, record) {
        if (record.isNew) {
          if (itemize[record.key]) {
            return null;
          }
          return (
            <Input
              placeholder="Discount"
              style={{ width: "100%" }}
              value={record.discount || ""}
              onChange={(e) => handleInputChange(e, record.key, "discount")}
            />
          );
        } else if (edit[record.key]) {
          return renderEditCellDiscount(record);
        } else {
          return text ? "$" + text : "-";
        }
      },
    },
    {
      title: "Patient Responsibility",
      dataIndex: "patientResponsibility",
      key: "patientResponsibility",
      width: columnWidths.patient_responsibility,
      render: function (text, record) {
        return text ? (
          "$" + text
        ) : !itemize[record.key] ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Button type="primary" style={{ borderRadius: "100px" }} size="small" onClick={() => handleSaveTreatment(record)}>
              Save
            </Button>
            <Button
              type="link"
              style={{ borderRadius: "100px" }}
              onClick={() => {
                setItemize((prev) => ({ ...prev, [record.key]: false }));
                setTableData((prevTableData) => prevTableData.filter((rowData) => rowData.key !== record.key));
                setDisableAdd(false);
              }}
            >
              Cancel
            </Button>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: columnWidths.action,
      render: function (_, record) {
        if (record.isNew) {
          return (
            <Checkbox checked={itemize[record.key]} onChange={(e) => setItemize((prev) => ({ ...prev, [record.key]: e.target.checked }))}>
              Itemize
            </Checkbox>
          );
        }

        return (
          <span>
            <Space size="middle">
              {record.isNew ? null : (
                <>
                  {edit[record.key] ? (
                    <Button
                      type="text"
                      icon={<img src={check} alt="Cancel" />}
                      style={{ color: "#979797" }}
                      onClick={function () {
                        cancelEdit(record);
                      }}
                    />
                  ) : (
                    <Button
                      type="text"
                      icon={<img src={edit1} alt="Edit" />}
                      style={{ color: "#979797" }}
                      onClick={function () {
                        handleEdit(record);
                      }}
                    />
                  )}
                  <Button
                    onClick={() => setModalState({ isVisible: true, deleteId: record })}
                    type="text"
                    icon={<img src={delete1} alt="Delete" />}
                    style={{ color: "#979797" }}
                  />
                  <Button
                    type="text"
                    icon={<img src={book} alt="Open" />}
                    style={{ color: "#979797" }}
                    onClick={function () {
                      handleOpen(record);
                    }}
                  />
                </>
              )}
            </Space>
          </span>
        );
      },
    },
  ];

  const pmsColumns = [
    {
      title: "Treatment",
      dataIndex: "treatment_name",
      key: "treatment_name",
      width: 100,
      ellipsis: true,
      render: (text) => <span style={{ maxWidth: "100px", display: "block" }}>{text}</span>, // Enforce width
    },
    {
      title: "Tooth / Quad",
      dataIndex: "tooth",
      key: "tooth",
      width: 100,
      ellipsis: true, // Enable ellipsis
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimated_cost",
      key: "estimated_cost",
      width: 100,
      ellipsis: true, // Enable ellipsis
      render: (value) => `$${value}`, // Add $ before the value
    },
    {
      title: "Insurance Estimate",
      dataIndex: "insurance_estimate",
      key: "insurance_estimate",
      width: 100,
      ellipsis: true, // Enable ellipsis
      render: (value) => `$${value}`, // Add $ before the value
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 70,
      ellipsis: true, // Enable ellipsis
      render: (value) => `%${value}`, // Add % after the value
    },
    {
      title: "Patient Responsibility",
      dataIndex: "patient_responsibility",
      key: "patient_responsibility",
      width: 120,
      ellipsis: true, // Enable ellipsis
      render: (value) => `$${value}`, // Add $ before the value
    },
  ];

  // const treatment =
  //   itemlist.length > 0 &&
  //   itemlist[0].treatment_plans &&
  //   Array.isArray(itemlist[0].treatment_plans) &&
  //   itemlist[0].treatment_plans.length > 0 &&
  //   itemlist[0].treatment_plans.find((plan) => plan.id === id);

  const patientName = localStorage.getItem("patientName") ? localStorage.getItem("patientName") : localStorage.getItem("newPatientName");

  return (
    <>
      <ModalStep3
        isModalVisible={isModalVisible1}
        setIsModalVisible={setIsModalVisible1}
        selectedMember={item}
        handleSuccessAddToServer={handleSuccessAddToServer}
        patientId={selectedPatientId}
        handleReadData={handleReadData}
        processTreatmentPlans={() => processTreatmentPlans(props.data)}
      />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Title level={4} style={{ marginBottom: 25 }}>
          {patientName ? patientName : props.data && props.data.first_name}'s Treatment Plan
        </Title>
        <Button
          style={{
            border: "1px solid #6B43B5",
            color: "#6B43B5",
            marginLeft: "auto",
            width: 154,
            height: 34,
          }}
          onClick={onBack}
        >
          Back
        </Button>
      </div>

      <Card>
        <>
          <Title className="step-title" level={5}>
            Doctor's Note
          </Title>
          <Input.TextArea
            className="textarea-shadow-border2"
            value={note}
            onChange={handleTextAreaChange}
            placeholder="Write Doctor’s Note..."
          />
        </>
        <>
          <Card>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Title className="step-title" level={5}>
                Recommended Treatments
              </Title>
              <Button
                style={{ marginLeft: "auto", color: "hsla(233, 8%, 55%, 1)" }}
                onClick={() => {
                  setIsModalVisible5(true);
                  setSelectModal(false);
                }}
              >
                <img src={eye} alt="eye icon" style={{ width: 16, height: 16, marginRight: 8 }} />
                PMS Data Preview
              </Button>
            </div>
            <div className="div-table1" ref={tableRef}>
              <Table
                dataSource={tableData}
                columns={columns3}
                // className="custom-table2"
                scroll={{ x: "320px" }}
                pagination={false}
                tableLayout="fixed" // Ensures fixed widths are respected
                expandedRowKeys={expandedRowKeys}
                rowSelection={rowSelection}
                onExpand={handleExpandTable}
                expandable={{
                  expandedRowRender: (record) => {
                    if (record.isNew) {
                      const EXTRA_COLUMNS_OFFSET2 = 1;
                      return (
                        <>
                          {/* This is a form for creating procedure for a new treatment */}
                          {itemize[record.key] &&
                            (record.isNew && newTreatmentProcedure ? (
                              <div style={{ marginTop: 20 }}>
                                <Row gutter={[10, 10]} align="middle">
                                  <Col span={EXTRA_COLUMNS_OFFSET2 * 3}></Col>
                                  <Col span={4} style={{ marginLeft: 26 }}>
                                    <Select
                                      placeholder="Procedure Code"
                                      style={{
                                        width: "100%",
                                        height: 42,
                                        border: "1px solid #6B43B5",
                                        borderRadius: 7,
                                      }}
                                      onChange={(value) => handleExpandedInputChange(value, record.key, "procedure_code")}
                                      value={expandedRowInputValues[record.key] && expandedRowInputValues[record.key].procedure_code}
                                      filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                                      showSearch
                                    >
                                      {procedures && procedures.length > 0 ? (
                                        procedures.map((item) =>
                                          item && item.id ? (
                                            <Select.Option key={item.id} value={item.id}>
                                              {`${item.procedure_code}-${item.procedure_code_description}`}
                                            </Select.Option>
                                          ) : null
                                        )
                                      ) : (
                                        <Select.Option disabled key={-2} value="">
                                          Empty
                                        </Select.Option>
                                      )}
                                    </Select>
                                  </Col>

                                  <Col span={3}>
                                    <Input
                                      placeholder="Tooth/Quad"
                                      style={{ width: "100%" }}
                                      onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "tooth_quad")}
                                      value={expandedRowInputValues[record.key] && expandedRowInputValues[record.key].tooth_quad}
                                    />
                                  </Col>

                                  <Col span={3}>
                                    <Input
                                      placeholder="Cost"
                                      style={{ width: "100%" }}
                                      onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "estimated_cost")}
                                      value={expandedRowInputValues[record.key] && expandedRowInputValues[record.key].estimated_cost}
                                    />
                                  </Col>

                                  <Col span={3}>
                                    <Input
                                      placeholder="Insurance"
                                      style={{ width: "100%" }}
                                      onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "insurance_estimate")}
                                      value={expandedRowInputValues[record.key] && expandedRowInputValues[record.key].insurance_estimate}
                                    />
                                  </Col>

                                  <Col span={4} style={{ marginRight: 25 }}>
                                    <Input
                                      placeholder="Discount"
                                      style={{ width: "110px" }}
                                      onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "discount")}
                                      value={expandedRowInputValues[record.key] && expandedRowInputValues[record.key].discount}
                                    />
                                  </Col>

                                  <Col
                                    span={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      style={{
                                        width: "65%",
                                        fontSize: 10,
                                        marginBottom: 5,
                                      }}
                                      onClick={() => saveTreatmentPlanToServer(record, record.key)}
                                      loading={loading}
                                    >
                                      Add
                                    </Button>
                                    <p
                                      style={{
                                        color: "#6B43B5",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontSize: "10px",
                                        margin: 0,
                                      }}
                                      onClick={() => setNewTreatmentProcedure(false)}
                                    >
                                      Cancel
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "50%",
                                  marginTop: 20,
                                  marginLeft: "auto",
                                  display: "flex",
                                  gap: 15,
                                }}
                              >
                                <Button style={{ width: "100%", height: 38 }} type="primary" onClick={handleAddNewTreatmentProcedure}>
                                  Add Procedure
                                </Button>
                                <Button
                                  style={{
                                    width: "100%",
                                    height: 38,
                                    color: "#6B43B5",
                                    border: "1px solid #6B43B5",
                                  }}
                                  onClick={() => handleAddExistingPro(record.key, record)}
                                >
                                  Add From PMS
                                </Button>
                              </div>
                            ))}

                          <Modal
                            title={selectModal ? "Treatments Pulled from PMS" : "PMS Data Preview"}
                            open={isModalVisible3}
                            centered
                            style={{
                              minWidth: 540,
                              height: 650,
                              // marginRight: selectModal ? 40 : "auto"
                            }}
                            footer={null}
                            onCancel={() => {
                              handleCancel3();
                            }}
                          >
                            {selectModal ? (
                              <>
                                <p style={{ color: "#848696" }}>You can select Procedures and add to selected Treatment.</p>
                                <Input
                                  style={{
                                    width: "100%",
                                    height: 43,
                                    borderRadius: 8,
                                    border: "1px solid #6B43B5",
                                  }}
                                  placeholder="Search Procedures"
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                                <Table
                                  columns={pmsColumns}
                                  dataSource={pms}
                                  rowKey="id" // Unique key for each row (assuming your data has a unique 'id' field)
                                  pagination={false}
                                  size="small"
                                  scroll={{ x: "max-content" }}
                                  style={{ border: "none", marginTop: 20 }}
                                  rowSelection={{
                                    type: "checkbox", // Allow multi-row selection
                                    selectedRowKeys: selectedRowKeys2,
                                    onChange: (newSelectedRowKeys) => {
                                      setSelectedRowKeys2(newSelectedRowKeys);
                                    },
                                  }}
                                />
                                <Button
                                  type="primary"
                                  style={{
                                    marginTop: 35,
                                    width: "100%",
                                    height: 45,
                                    borderRadius: 50,
                                  }}
                                  onClick={() => {
                                    saveTreatmentPlanToServer(record, record.key);
                                  }}
                                >
                                  Add to Treatment{" "}
                                </Button>
                              </>
                            ) : (
                              <Table
                                columns={pmsColumns}
                                dataSource={pms}
                                rowKey="id"
                                pagination={false}
                                style={{ marginTop: 20 }}
                                size="small"
                                scroll={{ x: "max-content" }}
                              />
                            )}
                          </Modal>
                        </>
                      );
                    }

                    const treatmentPlan =
                      itemlist.length > 0 &&
                      itemlist[0].treatment_plans &&
                      Array.isArray(itemlist[0].treatment_plans) &&
                      itemlist[0].treatment_plans.length > 0 &&
                      itemlist[0].treatment_plans.find((plan) => Number(plan.id) === Number(record.key));

                    if (!treatmentPlan || !treatmentPlan.treatment_procedures) {
                      return (
                        <div>
                          {/* Show only add procedure buttons when no procedures exist */}
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 150,
                              display: "flex",
                              gap: 15,
                            }}
                          >
                            <Button
                              style={{ width: "100%", height: 38 }}
                              type="primary"
                              onClick={setTreatmentProcedure((prevState) => [...prevState, record.key])}
                            >
                              Add Procedure
                            </Button>
                            <Button
                              style={{
                                width: "100%",
                                height: 38,
                                color: "#6B43B5",
                                border: "1px solid #6B43B5",
                              }}
                              onClick={() => handleAddExistingPro2(record.key, record)}
                            >
                              Add From PMS
                            </Button>
                          </div>
                        </div>
                      );
                    }

                    // Define columns for the inner table
                    const innerColumns = [
                      // ستون‌های خالی برای هماهنگ‌سازی با ستون‌های انتخاب و گسترش

                      // Empty
                      {
                        title: "", // عنوان خالی
                        dataIndex: "selection", // نام داده ای غیرضروری
                        key: "selection",
                        width: EXTRA_COLUMNS_WIDTH, // عرض مشابه ستون انتخاب
                        render: () => null, // بدون محتوا
                      },

                      // Empty
                      {
                        title: "", // عنوان خالی
                        dataIndex: "expand",
                        key: "expand",
                        width: EXTRA_COLUMNS_WIDTH, // عرض مشابه ستون گسترش
                        render: () => null, // بدون محتوا
                      },
                      // ستون‌های واقعی جدول داخلی

                      // Procedure Code Description
                      {
                        title: "Procedure Code Description",
                        dataIndex: ["procedure_code", "procedure_code_description"],
                        key: "procedure_code_description",
                        width: columnWidths.treatment,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            var selectedValue = "";
                            if (editedProcedures[index] && editedProcedures[index].procedure_code_description !== undefined) {
                              selectedValue = editedProcedures[index].procedure_code_description;
                            } else if (
                              procedure &&
                              procedure.procedure_code &&
                              procedure.procedure_code.procedure_code_description !== undefined
                            ) {
                              selectedValue = procedure.procedure_code.procedure_code_description;
                            }

                            return (
                              <Select
                                placeholder="Procedure Code"
                                style={{ width: "100%", height: 42 }}
                                onChange={function (value) {
                                  handleProcedureInputChange(value, index, "procedure_code_description");
                                }}
                                value={selectedValue}
                                filterOption={function (input, option) {
                                  var optionText = String(option.props.children).toLowerCase();
                                  return optionText.indexOf(input.toLowerCase()) !== -1;
                                }}
                                showSearch
                              >
                                {procedures.map(function (item) {
                                  return (
                                    <Select.Option key={item.id} value={item.id}>
                                      {item.procedure_code} - {item.procedure_code_description}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            );
                          } else {
                            var procedureCode = "-";
                            var procedureDescription = "-";
                            if (procedure.procedure_code && procedure.procedure_code.procedure_code) {
                              procedureCode = procedure.procedure_code.procedure_code;
                            }
                            if (procedure.procedure_code && procedure.procedure_code.procedure_code_description) {
                              procedureDescription = procedure.procedure_code.procedure_code_description;
                            }
                            return (
                              <span>
                                {procedureCode} - {procedureDescription}
                              </span>
                            );
                          }
                        },
                      },

                      // Tooth/Quad
                      {
                        title: "Tooth/Quad",
                        dataIndex: "tooth",
                        key: "tooth",
                        width: columnWidths.tooth,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            var inputValue = "";
                            if (editedProcedures[index] && editedProcedures[index].tooth !== undefined) {
                              inputValue = editedProcedures[index].tooth;
                            } else if (procedure.tooth !== undefined) {
                              inputValue = procedure.tooth;
                            }
                            return (
                              <Input
                                value={inputValue}
                                onChange={function (e) {
                                  handleProcedureInputChange(e.target.value, index, "tooth");
                                }}
                                placeholder="Tooth/Quad"
                              />
                            );
                          } else {
                            return <span>{procedure.tooth || "-"}</span>;
                          }
                        },
                      },

                      // Estimate Const
                      {
                        title: "Estimated Cost",
                        dataIndex: "estimated_cost",
                        key: "estimated_cost",
                        width: columnWidths.estimated_cost,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            var costValue = 0;
                            if (editedProcedures[index] && editedProcedures[index].estimated_cost !== undefined) {
                              costValue = editedProcedures[index].estimated_cost;
                            } else if (procedure.estimated_cost !== undefined) {
                              costValue = procedure.estimated_cost;
                            }
                            return (
                              <InputNumber
                                value={costValue}
                                onChange={function (value) {
                                  handleProcedureInputChange(value, index, "estimated_cost");
                                }}
                              />
                            );
                          } else {
                            return <span>${procedure.estimated_cost || "-"}</span>;
                          }
                        },
                      },

                      // Insurance
                      {
                        title: "Insurance Estimate",
                        dataIndex: "insurance_estimate",
                        key: "insurance_estimate",
                        width: columnWidths.insurance_estimate,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            var insuranceValue = 0;
                            if (editedProcedures[index] && editedProcedures[index].insurance_estimate !== undefined) {
                              insuranceValue = editedProcedures[index].insurance_estimate;
                            } else if (procedure.insurance_estimate !== undefined) {
                              insuranceValue = procedure.insurance_estimate;
                            }
                            return (
                              <InputNumber
                                value={insuranceValue}
                                onChange={function (value) {
                                  handleProcedureInputChange(value, index, "insurance_estimate");
                                }}
                              />
                            );
                          } else {
                            if (procedure.insurance_estimate === "0.00" || procedure.insurance_estimate === 0) {
                              return <span>$0.00</span>;
                            } else {
                              return <span>${procedure.insurance_estimate}</span>;
                            }
                          }
                        },
                      },

                      // {
                      //   title: "", // عنوان خالی
                      //   dataIndex: "expand",
                      //   key: "expand",
                      //   width: 30, // عرض مشابه ستون گسترش
                      //   render: () => null, // بدون محتوا
                      // },

                      // Dsicount
                      {
                        title: "Discount",
                        dataIndex: "discount",
                        key: "discount",
                        width: columnWidths.discount,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            var discountValue = 0;
                            if (
                              editedProcedures[index] &&
                              editedProcedures[index].discount !== undefined &&
                              editedProcedures[index].discount !== null
                            ) {
                              discountValue = editedProcedures[index].discount;
                            } else if (procedure && procedure.discount !== undefined && procedure.discount !== null) {
                              discountValue = procedure.discount;
                            }
                            return (
                              <InputNumber
                                value={discountValue}
                                formatter={function (value) {
                                  return value + "%";
                                }}
                                parser={function (value) {
                                  return value.replace("%", "");
                                }}
                                onChange={function (value) {
                                  handleProcedureInputChange(value, index, "discount");
                                }}
                              />
                            );
                          } else {
                            if (procedure.discount) {
                              return <span>%{procedure.discount}</span>;
                            } else {
                              return <span>0</span>;
                            }
                          }
                        },
                      },

                      // Patient Responsibilty
                      {
                        title: "Patient Responsibility",
                        dataIndex: "patient_responsibility",
                        key: "patient_responsibility",
                        width: columnWidths.patient_responsibility,
                        render: function (text, procedure) {
                          if (text) {
                            return <span>${text}</span>;
                          } else {
                            return <span>-</span>;
                          }
                        },
                      },

                      // Action
                      {
                        title: "Actions",
                        key: "actions",
                        width: columnWidths.action,
                        render: function (text, procedure, index) {
                          var isEditing = editingProcedureIndex === procedure.id;
                          if (isEditing) {
                            return (
                              <>
                                <Button
                                  type="text"
                                  onClick={function () {
                                    saveEditedProcedure(index, record, procedure.id);
                                  }}
                                  icon={<img src={check} alt="Save" />}
                                />
                              </>
                            );
                          } else {
                            return (
                              <Row style={{ flexWrap: "nowrap", marginRight: 50 }}>
                                <Button
                                  type="text"
                                  onClick={function () {
                                    setEditingProcedureIndex(procedure.id);
                                  }}
                                  icon={<img src={edit1} alt="Edit" />}
                                  style={{ marginRight: 20 }}
                                />
                                <Button
                                  type="text"
                                  icon={<img src={delete1} alt="Delete" />}
                                  onClick={function () {
                                    deleteProcedures(procedure.id);
                                  }}
                                />
                              </Row>
                            );
                          }
                        },
                      },
                    ];

                    // Prepare data for the inner table
                    const data = treatmentPlan.treatment_procedures.map((procedure, index) => ({
                      key: procedure.id,
                      ...procedure,
                      index,
                    }));

                    const EXTRA_COLUMNS_OFFSET = 1; // Number of spans to offset (assuming each extra column spans 1 span)

                    return (
                      <div>
                        {!treatmentProcedure.includes(record.key) && (
                          <Table
                            columns={innerColumns}
                            dataSource={data}
                            pagination={false}
                            // scroll={{ x: "320px" }}
                            rowKey="key"
                            expandedRowKeys={expandedRowKeys}
                            tableLayout="fixed" // Ensures fixed widths are respected
                            showHeader={false}
                            onExpand={handleExpandTable}
                          />
                        )}

                        {/* Form for adding new procedures for a treatment that is already created */}
                        {treatmentProcedure.includes(record.key) ? (
                          <div style={{ marginTop: 20 }}>
                            <Row gutter={[10, 10]} align="middle">
                              {/* Offset to account for selection and expand columns */}
                              {/* Adjust multiplier based on actual span of extra columns */}
                              <Col span={EXTRA_COLUMNS_OFFSET * 3}></Col>
                              {/* Procedure Code Select */}
                              <Col span={4} style={{ marginLeft: 26 }}>
                                <Select
                                  placeholder="Procedure Code"
                                  style={{
                                    width: "100%",
                                    height: 42,
                                    border: "1px solid #6B43B5",
                                    borderRadius: 7,
                                  }}
                                  onChange={(value) => handleExpandedInputChange(value, record.key, "procedure_code")}
                                  value={
                                    (expandedRowInputValues[record.key] && expandedRowInputValues[record.key].procedure_code) || undefined
                                  }
                                  filterOption={(input, option) => String(option.children).toLowerCase().includes(input.toLowerCase())}
                                  showSearch
                                >
                                  {procedures.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                      {item.procedure_code} - {item.procedure_code_description}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                              {/* Tooth/Quad Input */}
                              <Col span={3}>
                                <Input
                                  placeholder="Tooth/Quad test"
                                  style={{ width: "100%" }}
                                  onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "tooth_quad")}
                                  value={expandedRowInputValues[record.key] ? expandedRowInputValues[record.key].tooth_quad : ""}
                                />
                              </Col>
                              {/* Cost Input */}
                              <Col span={3}>
                                <Input
                                  placeholder="Cost"
                                  style={{ width: "100%" }}
                                  onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "estimated_cost")}
                                  value={expandedRowInputValues[record.key] ? expandedRowInputValues[record.key].estimated_cost : ""}
                                />
                              </Col>
                              {/* Insurance Input */}
                              <Col span={3}>
                                <Input
                                  placeholder="Insurance"
                                  style={{ width: "100%" }}
                                  onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "insurance_estimate")}
                                  value={
                                    expandedRowInputValues[record.key]
                                      ? expandedRowInputValues[record.key].insurance_estimate === "0.00"
                                        ? "-"
                                        : expandedRowInputValues[record.key].insurance_estimate
                                      : ""
                                  }
                                />
                              </Col>
                              {/* Discount Input */}
                              <Col span={4} style={{ marginRight: 25 }}>
                                <Input
                                  placeholder="Discount"
                                  style={{ width: "100px" }}
                                  onChange={(e) => handleExpandedInputChange(e.target.value, record.key, "discount")}
                                  value={expandedRowInputValues[record.key] ? expandedRowInputValues[record.key].discount : ""}
                                />
                              </Col>
                              {/* Add and Cancel Buttons */}
                              <Col
                                span={2}
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                              >
                                <Button
                                  type="primary"
                                  style={{
                                    width: "65%",
                                    fontSize: 10,
                                    marginBottom: 5,
                                  }}
                                  onClick={() => postProcedure(record, record.key)}
                                  loading={loading}
                                >
                                  Add
                                </Button>
                                <p
                                  style={{
                                    color: "#6B43B5",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontSize: "10px",
                                    margin: 0,
                                  }}
                                  onClick={() => {
                                    const filterItems = treatmentProcedure.filter((procedure) => procedure !== record.key);
                                    setTreatmentProcedure(filterItems);
                                  }}
                                >
                                  Cancel
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "50%",
                              marginTop: 20,
                              marginLeft: "auto",
                              display: "flex",
                              gap: 15,
                            }}
                          >
                            <Button
                              style={{ width: "100%", height: 38 }}
                              type="primary"
                              onClick={() => setTreatmentProcedure((prevState) => [...prevState, record.key])}
                            >
                              Add Procedure
                            </Button>
                            <Button
                              style={{
                                width: "100%",
                                height: 38,
                                color: "#6B43B5",
                                border: "1px solid #6B43B5",
                              }}
                              onClick={() => handleAddExistingPro2(record.key, record)}
                            >
                              Add From PMS
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  },
                  // Check if the record (row) is new or not, if it is, then make it expandable if the itemize is marked otherwise don't
                  rowExpandable: (record) => (record.isNew ? itemize[record.key] : true),
                }}
              />
            </div>
            <Button
              className={disableadd || isGettingTreatmentPlans ? "step1-button-disabled" : "step1-button"}
              type="default"
              style={disableadd || isGettingTreatmentPlans ? { color: "gray" } : {}}
              onClick={addTreatmentPlan}
              disabled={disableadd || isGettingTreatmentPlans}
            >
              Add Treatment
              <img src={circle} alt="" />
            </Button>
          </Card>
          <label className="inputLabel" style={{ fontSize: 18, fontWeight: "600", marginBottom: 20 }}>
            Files
          </label>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {imageFile.map((file, index) => {
              let urlObject;
              let pathname = "";
              let fileType = "";

              try {
                urlObject = new URL(file.path);
                pathname = urlObject.pathname;
                fileType = pathname.split(".").pop();
              } catch (e) {
                pathname = file.name || "";
                fileType = pathname.split(".").pop();
              }

              const fileType2 = file.file_type;

              // Get the appropriate icon based on file type
              const fileIcon = getFileIcon(fileType);

              return (
                <div
                  key={file.name}
                  className="uploaded-file-preview"
                  style={{
                    display: "inline-block",
                    marginRight: 10,
                    position: "relative",
                  }}
                >
                  {file.type && file.type.startsWith("image") ? (
                    <>
                      <img
                        src={getImageUrl(file)}
                        alt={folder}
                        style={{
                          objectFit: "cover",
                          borderRadius: 8,
                          border: "1px solid #6B43B5",
                          width: 199,
                          height: 132,
                          position: "relative",
                        }}
                      />

                      {/* Download Icon */}
                      <div
                        className="downloadIconContainer"
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "5",
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href={getImageUrl(file)} download style={{ position: "absolute", bottom: 5 }}>
                          <img width={15} height={15} src={downloadIcon} alt="download" />
                        </a>
                      </div>

                      <div
                        style={{
                          fontSize: 12,
                          color: "#6B43B5",
                          marginTop: 10,
                          textAlign: "center",
                          marginBottom: 45,
                          width: 200,
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {file.name}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: 200,
                        height: 133,
                        marginBottom: 65,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = file.path ? file.path : getImageUrl(file);
                        link.download = "";
                        link.click();
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #6B43B5",
                          borderRadius: 8,
                          width: 200,
                          height: 133,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ fontSize: 50, color: "#6B43B5" }}>
                          {fileType === "pdf" ||
                          fileType === "msword" ||
                          fileType === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                          fileType === "xlsx" ||
                          fileType === "pptx" ||
                          fileType === "zip" ||
                          fileType === "rar" ||
                          fileType === "docx" ||
                          fileType === "aspx" ||
                          fileType === "mp4" ||
                          fileType === "mkv" ? (
                            <>
                              <img src={fileIcon} alt={`${fileType2} icon`} width={70} height={70} />
                              <div
                                className="downloadIconContainer"
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: "5",
                                  width: 30,
                                  height: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <a href={file.path ? file.path : getImageUrl(file)} download style={{ position: "absolute", bottom: -20 }}>
                                  <img width={15} height={15} src={downloadIcon} alt="download" />
                                </a>
                              </div>
                              {/* <p style={{width: 200}}>{file.file_name}</p> */}
                            </>
                          ) : (
                            <>
                              <img
                                src={file.path}
                                alt={folder}
                                style={{
                                  objectFit: "cover",
                                  width: 198,
                                  height: 131,
                                  borderRadius: 8,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              />
                              <div
                                className="downloadIconContainer"
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: "5",
                                  width: 30,
                                  height: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <a href={file.path} download style={{ position: "absolute", bottom: -20 }}>
                                  <img width={15} height={15} src={downloadIcon} alt="download" />
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "#6B43B5",
                          marginTop: 10,
                          textAlign: "center",
                          marginBottom: 45,
                          width: 200,
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {file.name}
                      </div>
                    </div>
                  )}
                  <button
                    onClick={() => handleDelete2(file.id, index)}
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      background: "transparent",
                      color: "#B7B7B7",
                      border: "none",
                      borderRadius: "50%",
                      width: 23,
                      height: 20,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    x
                  </button>
                </div>
              );
            })}
            <div
              style={{
                width: 200,
                padding: 0,
                border: "1px dashed #B7B7B7",
                borderWidth: 3,
                borderRadius: 8,
                display: "inline-block",
                verticalAlign: "top",
                marginLeft: 15,
                height: 133,
              }}
            >
              <div className="clearfix" style={{ width: "100%" }}>
                <Upload
                  key={uploadKey}
                  onChange={handleUploadChange}
                  onRemove={handleRemove}
                  fileList={imageFile} // Controlled fileList
                  showUploadList={false} // Hide the uploaded files list
                >
                  <label
                    className="formLabel"
                    style={{
                      color: "gray",
                      backgroundColor: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                      maxWidth: "150px",
                      minWidth: "unset",
                      paddingTop: 15,
                      paddingBottom: 15,
                      flexDirection: "column",
                      fontSize: 14,
                    }}
                  >
                    <div style={{ top: -15, position: "relative", marginLeft: 45 }}>
                      <img src={add} alt="" />
                    </div>
                    <div
                      style={{
                        color: "#B7B7B7",
                        fontSize: 12,
                        marginBottom: 5,
                        marginLeft: 50,
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Drag and drop or <span style={{ color: "#6B43B5" }}>Browse</span> your files
                    </div>
                  </label>
                </Upload>
              </div>
            </div>

            <style jsx>{`
              @media (max-width: 768px) {
                .clearfix {
                  width: 100% !important;
                }

                .formLabel {
                  max-width: 120px !important;
                  margin-left: 0 !important;
                  padding: 10px !important;
                }

                Col {
                  margin-left: 0 !important;
                  height: auto !important;
                  padding: 10px !important;
                }
              }
            `}</style>
          </div>
        </>
      </Card>
      <div className="step2-div" style={{ marginBottom: 100, width: "100%" }}>
        <Row style={{ display: "flex", gap: 20 }}>
          <Button
            className="render-btn2"
            style={{
              marginRight: 10,
              border: "1px solid #6B43B5",
              color: "#6B43B5",
            }}
            onClick={handleButtonClick}
          >
            View Sent Draft
          </Button>
          <Button
            className="render-btn2"
            style={{
              marginRight: 10,
              border: "1px solid #6B43B5",
              color: "#6B43B5",
            }}
            onClick={handlePreviewClick}
          >
            Preview
          </Button>
        </Row>
        <Row style={{ marginLeft: "auto" }}>
          <Button
            className="render-btn2"
            onClick={() => handleApprove2("draft")}
            // loading={isLoading1}
            style={{
              marginRight: 10,
              border: "1px solid #6B43B5",
              color: "#6B43B5",
            }}
            // disabled={!selectedTreatmentIds || selectedTreatmentIds.length === 0}
          >
            Save
          </Button>
          <Button
            className="render-btn2"
            type="primary"
            onClick={() => handleApprove2("sent")}
            // loading={isLoading}
            // disabled={!selectedTreatmentIds || selectedTreatmentIds.length === 0}
          >
            Send
          </Button>
        </Row>
      </div>
      <Modal
        title="Educational Content"
        open={isModalVisible}
        style={{
          minWidth: 300,
          minHeight: 800,
        }}
        footer={null}
        onCancel={handleCancel}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spin size="large" /> {/* Ant Design spinner */}
          </div>
        ) : edulist && edulist.educational_content && edulist.educational_content.length > 0 ? (
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p style={{ fontSize: 16, marginTop: 25 }}>Treatment Description</p>
              {modaledit ? (
                <Button
                  type="text"
                  icon={<img src={tick} alt="Open" />}
                  style={{
                    color: "#979797",
                    marginLeft: "auto",
                    marginTop: 25,
                  }}
                  onClick={() => updateEducational2()}
                />
              ) : (
                <Button
                  type="text"
                  icon={<img src={edit2} alt="Open" />}
                  style={{
                    color: "#979797",
                    marginLeft: "auto",
                    marginTop: 25,
                  }}
                  onClick={() => {
                    if (edulist && edulist.educational_content && edulist.educational_content[0]) {
                      handleEditModal(edulist.educational_content[0].id);
                    } else {
                      console.error("Educational content or current page is undefined");
                    }
                  }}
                />
              )}
            </div>
            <div style={{ marginBottom: "20px" }}>
              {modaledit ? (
                <div>
                  <Input
                    style={{ width: "100%", height: 74, borderRadius: 7 }}
                    placeholder="Write Treatment Description..."
                    onChange={(e) => setDesc2(e.target.value)}
                    value={desc2 || edulist.educational_content[0].description || "-"} // Default value from <p>
                  />
                </div>
              ) : (
                <p style={{ fontSize: 12, color: "#848696" }}>{edulist.educational_content[0].description || "-"}</p>
              )}
            </div>
            <div
              style={{
                marginBottom: 15,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: 16 }}>Educational Content</p>
              {modaledit2 ? (
                <Button
                  type="text"
                  icon={<img src={tick} alt="Open" />}
                  style={{ color: "#979797", marginLeft: "auto" }}
                  onClick={() => updateEducational3()}
                />
              ) : (
                <Button
                  type="text"
                  icon={<img src={edit2} alt="Open" />}
                  style={{ color: "#979797", marginLeft: "auto" }}
                  onClick={() => {
                    let currentContent = null;
                    let contentId = null;

                    // Check if edulist and educational_content exist and are valid
                    if (
                      edulist &&
                      edulist.educational_content &&
                      Array.isArray(edulist.educational_content) &&
                      edulist.educational_content.length > 0
                    ) {
                      currentContent = edulist.educational_content[0]; // Access the first element directly
                      contentId = currentContent.id; // Extract the id
                    } else {
                      console.error("educational_content is missing or empty.");
                    }

                    handleEditModal2(contentId);
                  }}
                />
              )}
            </div>
            <Card>
              {modaledit2 ? (
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          type="primary"
                          style={{
                            background: "white",
                            color: "#6B43B5",
                            width: "100%",
                            border: "1px solid #6B43B5",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            height: 52,
                          }}
                          onClick={() => handleOpen2()}
                        >
                          Add Yours
                        </Button>
                      </div>
                    </>
                  )}
                  value={eduvalue} // Bind the value to the preselected and selected items
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      // Collect all video_file IDs from educational_content
                      const targetIds = edulist.educational_content.flatMap((content) => content.video_file.map((video) => video.id));

                      // Filter `targetIds` to only those matching IDs in `edu`
                      const matchingIds = edu.filter((item) => targetIds.includes(item.id)).map((item) => item.id);

                      // Combine matching IDs with current selections
                      const updatedSelectedItems = [...new Set([...eduvalue, ...matchingIds])];

                      // Update the state with preselected items
                      setEduValue(updatedSelectedItems);
                    }
                  }}
                  onChange={(selectedItems) => {
                    // Update the selected items when the user changes selection
                    setEduValue(selectedItems);
                  }}
                  style={{
                    width: "100%",
                    height: 42,
                    borderRadius: 7,
                    border: "1px solid #6B43B5",
                  }}
                  placeholder="Search Content"
                >
                  {edu && edu.length > 0 ? (
                    edu.map((item) =>
                      item.id ? (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name || item.description || `ID: ${item.id}`}
                        </Select.Option>
                      ) : null
                    )
                  ) : (
                    <Select.Option disabled key={-2} value="">
                      empty
                    </Select.Option>
                  )}
                </Select>
              ) : hasVideo ? (
                isYouTube || isSpearEdu || isPatientApi ? (
                  <iframe
                    width="100%"
                    height="246px"
                    src={videoLink.replace("watch?v=", "embed/")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Educational Video"
                  ></iframe>
                ) : (
                  <video width="100%" height="246px" controls>
                    <source src={videoLink || currentVideo.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )
              ) : (
                <p>No video available</p>
              )}
            </Card>
            <Row justify="center" style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
              <Button
                type="text"
                icon={<img src={arrowLeft} alt="Open" />}
                style={{ marginTop: 25 }}
                disabled={currentPage === 0}
                onClick={handlePrevious}
              />
              <span style={{ margin: "0 10px", lineHeight: "1.5", marginTop: 25 }}> {currentPage + 1} </span>
              <Button
                type="text"
                icon={<img src={arrowRight} alt="Open" />}
                style={{ marginTop: 25 }}
                disabled={currentPage === totalPages - 1}
                onClick={handleNext}
              />
            </Row>
          </div>
        ) : (
          <>
            <div>
              <div style={{ marginBottom: "20px" }}>
                <p>Treatment Description</p>
                <Input
                  style={{ width: "100%", height: 74, borderRadius: 7 }}
                  placeholder="Write Treatment Description..."
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                />
              </div>

              <div>
                <p>Educational Contents</p>
                <Select
                  mode="multiple"
                  value={eduvalue}
                  maxTagCount={1}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          type="primary"
                          style={{
                            background: "white",
                            color: "#6B43B5",
                            width: "100%",
                            border: "1px solid #6B43B5",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            height: 52,
                          }}
                          onClick={() => handleOpen2()}
                        >
                          Add Yours
                        </Button>
                      </div>
                    </>
                  )}
                  onChange={(selectedItems) => {
                    setEduValue(selectedItems); // Limit selection to 1
                  }}
                  style={{
                    width: "100%",
                    height: 42,
                    borderRadius: 7,
                    border: "1px solid #6B43B5",
                  }}
                  placeholder="Search Content"
                >
                  {edu && edu.length > 0 ? (
                    edu.map((item) =>
                      item && item.id ? (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ) : null
                    )
                  ) : (
                    <Select.Option disabled key={-2} value="">
                      empty
                    </Select.Option>
                  )}
                </Select>
              </div>
            </div>
            <Row justify="end" style={{ marginTop: 35 }}>
              <Button
                type="primary"
                onClick={() => {
                  updateEducational();
                }}
              >
                Done
              </Button>
            </Row>
          </>
        )}
      </Modal>
      <Modal
        open={isModalVisible2}
        style={{
          minWidth: 300,
          minHeight: 800,
        }}
        footer={null}
        onCancel={handleCancel2}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <p style={{ fontSize: 16, fontWeight: 600, marginTop: 30 }}>Add Educational Content for Invisalign</p>

          <Form.Item label="Content Name" name="contentName" rules={[{ required: true, message: "Please enter the content name!" }]}>
            <Input
              style={{
                height: 39,
                borderRadius: 8,
                border: "1px solid #6B43B5",
              }}
              placeholder="Enter Name for Content"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Content Description"
            name="contentDescription"
            rules={[
              {
                required: true,
                message: "Please enter the content description!",
              },
            ]}
          >
            <Input
              style={{ height: 74, borderRadius: 8 }}
              placeholder="Write Content Description..."
              rows={4}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Label Text */}
                Content
                {/* Checkbox with Title */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <span style={{ marginRight: 10, marginLeft: 330 }}>Redirect</span>
                  <Checkbox onChange={handleCheckboxChange} checked={isChecked} />
                </div>
              </div>
            }
            name="contentUrl"
            rules={[{ required: false, message: "Please enter the content URL!" }]}
          >
            <Input
              style={{
                height: 39,
                borderRadius: 8,
                border: "1px solid #6B43B5",
              }}
              placeholder="Enter Content URL"
              onChange={(e) => setUrl(e.target.value)}
              disabled={videoFile.length > 0}
            />
          </Form.Item>

          <Form.Item>
            <Col
              style={{
                padding: 0,
                border: "1px dashed #B7B7B7",
                borderWidth: 3,
                borderRadius: 8,
                display: "inline-block",
                verticalAlign: "top",
                marginLeft: 15,
                width: "95%",
              }}
            >
              <div className="clearfix" style={{ width: "100%" }}>
                <Upload
                  beforeUpload={() => false}
                  showUploadList={false}
                  // fileList={toFileList(imageFile)}
                  onChange={handleUploadChange2}
                  disabled={!!url}
                >
                  <label
                    className="formLabel"
                    style={{
                      color: "gray",
                      backgroundColor: "none",
                      display: "flex",
                      height: "81px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                      maxWidth: "250px",
                      minWidth: "unset",
                      padding: "15px",
                      flexDirection: "column",
                      fontSize: 14,
                      marginLeft: "40%",
                    }}
                  >
                    <div style={{ top: -15, position: "relative" }}>
                      <img src={add} alt="" style={{}} />
                    </div>
                    <div
                      style={{
                        color: "#B7B7B7",
                        fontSize: 12,
                        marginBottom: 5,
                        width: "100%",
                      }}
                    >
                      Drag and drop or <span style={{ color: "#6B43B5" }}>Browse</span> your files
                    </div>
                  </label>
                </Upload>
              </div>
            </Col>
          </Form.Item>
          <Row justify="end" style={{ marginTop: 35 }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  postEducational();
                }}
                style={{ width: 139, height: 38, fontSize: 16 }}
              >
                Add
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={selectModal ? "Treatments Pulled from PMS" : "PMS Data Preview"}
        open={isModalVisible5}
        centered
        style={{
          minWidth: 540,
          height: 650,
          // marginRight: selectModal ? 40 : "auto"
        }}
        footer={null}
        onCancel={() => {
          handleCancel4();
        }}
      >
        {selectModal ? (
          <>
            <p style={{ color: "#848696" }}>You can select Procedures and add to selected Treatment.</p>
            <Input
              style={{
                width: "100%",
                height: 43,
                borderRadius: 8,
                border: "1px solid #6B43B5",
              }}
              placeholder="Search Procedures"
              value={search} // <-- Bind the input to `search`
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="div-table2">
              <Table
                columns={pmsColumns}
                dataSource={pms}
                rowKey="id" // Unique key for each row (assuming your data has a unique 'id' field)
                pagination={false}
                size="small"
                scroll={{ x: "450px" }}
                // style={{ marginTop: 20 }}
                rowSelection={{
                  type: "checkbox", // Allow multi-row selection
                  selectedRowKeys: selectedRowKeys2,
                  onChange: (newSelectedRowKeys) => setSelectedRowKeys2(newSelectedRowKeys),
                }}
              />
            </div>
            <Button
              type="primary"
              loading={loading}
              style={{
                marginTop: 35,
                width: "100%",
                height: 45,
                borderRadius: 50,
              }}
              onClick={() => {
                postExistingPro();
              }}
            >
              Add to Treatment{" "}
            </Button>
          </>
        ) : (
          <div className="div-table2" style={{ marginTop: 20 }}>
            <Table columns={pmsColumns} dataSource={pms} rowKey="id" pagination={false} size="small" scroll={{ x: "450px" }} />
          </div>
        )}
      </Modal>

      <Modal
        onCancel={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        open={modalState.isVisible}
        footer={null}
        title="Delete Treatment"
      >
        <p>Are you sure you want to delete this treatment?</p>
        <Row justify={"end"}>
          <Button
            type="primary"
            onClick={() => {
              setModalState({ ...modalState, isVisible: false });
            }}
            style={{ marginRight: "10px" }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              console.log(modalState.deleteId);
              handleDelete(modalState.deleteId);
              setModalState({ isVisible: false, deleteId: null });
            }}
          >
            Yes
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default TreatmentCardStep2;
