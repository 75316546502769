import React, {useEffect, useState} from "react";
// import { useLocation } from "react-router-dom";

import TreatmentPlan from "../../../New/Treatment Plan/TreatmentPlan";

export default function Preview() {
    // const location = useLocation();
    // const { filteredData2, imageFile } = location.state || {}; 
    const [filteredData2, setFilteredData2] = useState(null);
    const [imageFile, setImageFile] = useState(null);
  
    useEffect(() => {
      // Retrieve each piece of data separately
      const storedFilteredData2 = localStorage.getItem("filteredData2");
      const storedImageFile = JSON.parse(localStorage.getItem("imageFile")); // Parse JSON to get the array
  
      if (storedFilteredData2) {
        setFilteredData2(JSON.parse(storedFilteredData2));
      }
      if (storedImageFile) {
        setImageFile(storedImageFile);
        console.log('chie', storedImageFile)
      }
    }, []);
  return (
    <>
        <>
        <TreatmentPlan preview={filteredData2} image = {imageFile} />
        </>
    </>
  );
}
