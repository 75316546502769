import { Table, Typography, ConfigProvider } from "antd";
import React, { useState, useEffect } from "react";
import { Row } from "antd";
import mkv from "../../assets/icons/mkv.svg";
import mp4 from "../../assets/icons/mp4.svg";
import power from "../../assets/icons/power.svg";
import pdf from "../../assets/icons/pdf.svg";
import excel from "../../assets/icons/excel.svg";
import word from "../../assets/icons/word.svg";
import downloadIcon from "../assets/icon/download-icon.png";
import folder from "../assets/icon/folder-open.svg";
import config from "../../config";

const { Text } = Typography;

const DownloadImage = (props) => {
  console.log(props.src);
  const getImageSrc = (url) => {
    const extension = url.split(".").pop().toLowerCase(); // Extract the file extension from the URL
    switch (extension) {
      case "pdf":
        return pdf;
      case "doc":
        return word;
      case "docx":
        return word;
      case "xlsx":
        return excel;
      case "ppt":
        return power;
      case "pptx":
        return power;
      case "rar":
      case "aspx":
      case "mp4":
        return mp4;
      case "mkv":
        return mkv;
      case "zip":
        return folder; // Provide path to the PowerPoint icon
      default:
        return url; // Return the original URL for other file types
    }
  };
  return (
    <div
      className="imageFileContainer"
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        flexDirection: "column",
      }}
      onClick={() => {
        const link = document.createElement("a");
        link.href = props.src ? props.src : getImageSrc(props.src);
        link.download = "";
        link.click();
      }}
    >
      <img
        width={200}
        height={130}
        style={{ borderRadius: "8px", border: "1px solid #6B43B5" }}
        alt="file"
        src={getImageSrc(props.src)}

        // className={"mt10 mr10"}
      />
      {/* <div className='downloadIconContainer'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: "5"
                }}
            >
                <a href={props.src} download>
                    <img
                        width={15}
                        height={15}
                        src={downloadIcon}
                        alt="download"
                    />
                </a>
            </div> */}
      <div
        className="downloadIconContainer"
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -40%)",
          zIndex: "5",
          width: 30,
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href={props.src}
          download
          style={{ position: "absolute", bottom: 5 }}
        >
          <img width={15} height={15} src={downloadIcon} alt="download" />
        </a>
      </div>
      <div
        style={{
          fontSize: 12,
          color: "#6B43B5",
          marginTop: 5,
          textAlign: "center",
          // marginBottom: 45,
          width: 210,
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {props.name}
      </div>
    </div>
  );
};

const TreatmentPlanFile = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      console.log(props.data);
    }
  }, [props]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#983cfc",
          controlItemBgHover: "#c293ff",
          colorLink: "#983cfc",
          borderRadius: "4px",
        },
        components: {
          Table: {
            borderRadius: "8px",
            borderColor: "#eee",
          },
        },
      }}
    >
      <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
        Files
      </Typography>
      <br />
      <Row>
        {data && data.image_files && data.image_files.length > 0
          ? data.image_files.map((img) => (
              <DownloadImage
                key={img.id}
                src={img.image}
                name={img.file_name}
              />
            ))
          : data && data.length > 0
          ? data.map(
              (item) =>
                item.path && (
                  <DownloadImage
                    key={item.id}
                    src={item.path}
                    name={item.name}
                  />
                )
            )
          : null}
      </Row>
    </ConfigProvider>
  );
};

export default TreatmentPlanFile;
