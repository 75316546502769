import React from "react"; 
import {
    Row,
    Col,
    Card,
    Button,
    Typography
} from "antd";
import NewDashboardLayout from "../component/NewDashboardLayout";
import TreatmentPlanTable from "./TreatmentPlanTable";
import TreatmentPlanFile from "./TreatmentPlanFile";
import "./style.css";
import infoCircleIcon from "../assets/icon/info-circle.png";
import ToothImage from "./ToothImage";
import DoctorNote from "./DoctorNote";
import SimulatorTooth from "./SimulatorTooth";
import { controller } from "../controller";
import PoweredBy from '../../components/CommonUi/PoweredBy'
import call from '../../assets/icons/call.svg'
import loc from '../../assets/icons/location.svg'

const TreatmentPlan = (props) => {
    const [data, setData] = React.useState([]);

    const handleGetData = async () => {
        var url = window.location.href;
        const urlParams = new URLSearchParams(url.slice(url.indexOf('?')));
        const patientId = urlParams.get('patient_id');
        if(patientId) {
            const response = await controller.getTreatmentPlan(patientId);
            const jsonData = await response.json; // Correctly parsing JSON from the response
            setData(jsonData);
            console.log(jsonData); // Log the fetched data
        } 
    };

    React.useEffect(() => {
        handleGetData();
    }, []);

    // Ensure data or props.preview is an array (default to empty array if not)
    const treatmentData = Array.isArray(data) && data.length > 0 ? data : (Array.isArray(props.preview) && props.preview.length > 0 ? props.preview : []);
    console.log(treatmentData)
    const file = treatmentData[0] && treatmentData[0].image_files && treatmentData[0].image_files.length > 0 ? treatmentData[0] :  props.image
    console.log('kkk', file)
    const office = JSON.parse(localStorage.getItem('office_ids'));
    // const logoUrl =
    // office[0] && office[0].length > 0 &&
    // office[0].logo &&
    // office[0].logo.startsWith('http')
    //   && `http://api.smilepass.com${office[0].logo}`

    return (
        <div style={{ background: 'white', height: '100%' }}>
            {treatmentData.length > 0 && (
                <div className="new-cards mb10">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginBottom: 20 }} className="resp-logo">
                        <img 
                            src={treatmentData[0] && treatmentData[0].office && treatmentData[0].office.office_logo 
                                ? treatmentData[0].office.office_logo 
                                : office[0] && office[0].logo && `http://api.smilepass.com${office[0].logo}` } 
                            alt=""  
                            className="resp-size" 
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <p style={{ marginBottom: 5, fontSize: 24, fontWeight: 600 }}>
                                {treatmentData[0] && treatmentData[0].office && treatmentData[0].office.name 
                                    ? treatmentData[0].office.name 
                                    : office && office[0].office_name}
                            </p>
                            <div style={{ width: '100%', display: 'flex', gap: 10 }} className="resp-div">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                    <img src={loc} alt="" style={{ width: 20, height: 20 }} />
                                    <p style={{ margin: 0, fontSize: 20 }}>
                                        {treatmentData[0] && treatmentData[0].office && treatmentData[0].office.address 
                                            ? treatmentData[0].office.address 
                                            : office && office[0].address}
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, marginBottom: 30 }}>
                                <img src={call} alt="" style={{ width: 20, height: 20 }} />
                                <p style={{ margin: 0, fontSize: 20 }}>
                                    {treatmentData[0] && treatmentData[0].office && treatmentData[0].office.phone 
                                        ? treatmentData[0].office.phone 
                                        : office && office[0].phone}
                                </p>
                            </div>
                        </div>
                    </div>
                    <p style={{ fontSize: 32, fontWeight: 600 }}>Treatment Plan</p>
                    <p style={{ fontSize: 20, fontWeight: 400, marginBottom: 50 }}>
                        Patient Name: {treatmentData[0] && treatmentData[0].patient && treatmentData[0].patient.first_name 
                            ? treatmentData[0].patient.first_name + " " + treatmentData[0].patient.last_name 
                            : treatmentData[0].first_name + ' ' + treatmentData[0].last_name}
                    </p>
                    <Card>
                        <div style={{ paddingTop: "25px", paddingLeft: "10px" }}>
                            <DoctorNote doctor_note={treatmentData[0] && treatmentData[0].note} />
                        </div>
                        <div style={{ padding: "25px 30px", border: '1px solid #F0F0F0', borderRadius: 8 }} className="resp-table">
                            <p style={{ fontSize: 16, fontWeight: 600 }}>Recommended Treatments</p>
                            <TreatmentPlanTable data={treatmentData} />
                        </div>
                        <div style={{ padding: "25px 5px" }}>
                            <TreatmentPlanFile data={file} />
                        </div>
                    </Card>
                </div>
            )}
            <PoweredBy />
        </div>
    );
};

export default TreatmentPlan;
