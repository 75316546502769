import { Badge, Layout, Menu, Modal } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { controller } from "../controller";
import "./app.local.css";

// Icons
import dashboard from "../assets/icons/dashboardIcon.png";
import member from "../assets/icons/memberIcon.png";
import treatmentPlan from "../assets/icons/data.png";
import payment from "../assets/icons/payment.png";
import appointment from "../assets/icons/appointment.png";
import dataIcon from "../assets/icons/data.png";
import office from "../assets/icons/office.png";
import setting from "../assets/icons/ticket20.png";
import logoSmall from "../assets/img/logo-collapsed.png";
import ticket from "../assets/icons/ticket.png";
import market from "../assets/icons/market.png";
import MenuItem from "antd/es/menu/MenuItem";

const { SubMenu } = Menu;
const { Sider } = Layout;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Sidebar({ dispatch, reloadSidebar }) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [collapsed, setCollapsed] = useState(() => {
    const savedCollapsed = localStorage.getItem("collapsed");
    if (savedCollapsed !== null) {
      return JSON.parse(savedCollapsed);
    } else {
      return window.innerWidth < 600;
    }
  });

  const [officeProfile, setOfficeProfile] = useState(
    localStorage.getItem("smilepassLogo")
      ? JSON.parse(localStorage.getItem("smilepassLogo")).data
      : []
  );

  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  const [displaySidebar, setDisplaySidebar] = useState("none");

  const [default_hover] = useState(
    window.location.href.includes("dashboard")
      ? "0"
      : window.location.href.includes("office/appointments")
      ? "15"
      : window.location.href.includes("upload-pdf")
      ? "2"
      : window.location.href.includes("add-provider")
      ? "3"
      : window.location.href.includes("provider-scheduling")
      ? "5"
      : window.location.href.includes("appointments")
      ? "6"
      : window.location.href.includes("treatment-plans")
      ? "treatmentSub"
      : window.location.href.includes("campaigns")
      ? "markettSub"
      : window.location.href.includes("members")
      ? "60"
      : window.location.href.includes("plans-management")
      ? "72"
      : window.location.href.includes("plan")
      ? "70"
      : window.location.href.includes("visit")
      ? "80"
      : window.location.href.includes("ar")
      ? "9"
      : window.location.href.includes("transactions")
      ? "141"
      : window.location.href.includes("add-connected-account")
      ? "4"
      : window.location.href.includes("Services-management")
      ? "71"
      : window.location.href.includes("failed-payments")
      ? "142"
      : window.location.href.includes("discount-options")
      ? "143"
      : window.location.href.includes("office/profile")
      ? "8"
      : window.location.href.includes("office/review")
      ? "12"
      : window.location.href.includes("data/health-categories")
      ? "30"
      : window.location.href.includes("data/procedures")
      ? "31"
      : window.location.href.includes("offices")
      ? "132"
      : "1"
  );

  const [openSubMenus, setOpenSubMenus] = useState([""]);

  useEffect(() => {
    if (window.location.href.includes("dashboard")) {
      setOpenSubMenus([]);
    } else if (window.location.href.includes("treatment-plans")) {
      setOpenSubMenus(["treatmentSub"]);
    } else if (window.location.href.includes("campaigns")) {
      setOpenSubMenus(["markettSub"]);
    } else if (
      window.location.href.includes("members") ||
      window.location.href.includes("plans") ||
      window.location.href.includes("visit")
    ) {
      setOpenSubMenus(["sub1"]);
    } else if (
      (window.location.href.includes("appointments") &&
        !window.location.href.includes("office/appointments")) ||
      window.location.href.includes("add-provider") ||
      window.location.href.includes("discount-options") ||
      window.location.href.includes("provider-scheduling")
    ) {
      setOpenSubMenus(["sub3"]);
    } else if (
      window.location.href.includes("add-connected-account") ||
      window.location.href.includes("officesprovider") ||
      window.location.href.includes("offices")
    ) {
      setOpenSubMenus(["sub4"]);
    } else if (
      window.location.href.includes("office/review") ||
      window.location.href.includes("office/profile") ||
      window.location.href.includes("Services") ||
      window.location.href.includes("office/appointments")
    ) {
      setOpenSubMenus(["sub5"]);
    } else if (
      window.location.href.includes("data/health-categories") ||
      window.location.href.includes("data/procedures")
    ) {
      setOpenSubMenus(["sub5-data"]);
    } else {
      setOpenSubMenus(["sub2"]);
    }
  }, []);

  const handleOpenChange = (value) => {
    setOpenSubMenus(value);
  };

  const checkPracticeAdmin = async () => {
    const response = await controller.checkPracticeAdmin();
    if (response.data.result && response.data.result === "super_admin") {
      setIsPracticeAdmin(true);
    } else {
      setIsPracticeAdmin(false);
    }
  };

  const [count, setCount] = useState(0);

  const getCounterData = async () => {
    const response = await controller.getBadge();
    if (response.data) setCount(response.data);
  };

  useEffect(() => {
    getCounterData();
  }, [reloadSidebar]);

  useEffect(() => {
    getLogo();
    getCounterData();
    checkPracticeAdmin();

    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
      if (dimensions.width < 800) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }

    window.addEventListener("resize", handleResize);
    // Call handleResize initially to set the correct state
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getLogo = async () => {
    const response = await controller.getLogo();
    setOfficeProfile(response.data);
  };

  const onCollapse = (collapsedValue) => {
    setOpenSubMenus([]);
    setCollapsed(collapsedValue);
    localStorage.setItem("collapsed", collapsedValue);
  };

  return (
    <div className="sidebar_container">
      <div className="iconMenuSideBae">
        <MenuOutlined
          onClick={() => {
            setDisplaySidebar("absolute");
          }}
          style={
            windowDimensions
              ? windowDimensions.width > 600
                ? { display: "none" }
                : windowDimensions.width > 750
                ? { fontSize: "22px", color: "#7A08FA" }
                : { fontSize: "15px", color: "#7A08FA" }
              : { display: "none" }
          }
        />
      </div>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        className="collapsedMenu"
        width={260}
      >
        <div className="logoMenu p0">
          {!collapsed ? (
            <img
              src={officeProfile.dark}
              alt="smilepass"
              className="smpassLogo"
            />
          ) : (
            <img
              height={160}
              src={logoSmall}
              alt="smilepass"
              className="smpassLogoCollpased"
            />
          )}
        </div>

        <Menu
          className="collapsedMenu"
          defaultSelectedKeys={[default_hover]}
          mode="inline"
          openKeys={openSubMenus}
          onOpenChange={handleOpenChange}
          theme="dark"
        >
          <Menu.Item
            className="sidebar_menuitem"
            key="0"
            onClick={() => dispatch(push("/dashboard"))}
          >
            <img src={dashboard} alt="" className="Icon" />
            <span style={{ fontSize: 18 }}>
              {collapsed ? "" : "Dashboard"}
            </span>
          </Menu.Item>
          <MenuItem
            key="treatmentSub"
            className="sidebar_menuitem"
            onClick={() => dispatch(push("/treatment-plans"))}
          >
            <span className="align-menu">
              <img src={treatmentPlan} alt="" className="Icon" />
              <span style={{ fontSize: 18 }}>
                {collapsed ? "" : "Treatment Plans"}
              </span>
            </span>
          </MenuItem>

          {/* Membership SubMenu */}
          {/* <SubMenu
            key="sub1"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={member} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "Membership"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="60"
              onClick={() => dispatch(push("/members"))}
            >
              <span>Members</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="70"
              onClick={() => dispatch(push("/plans"))}
            >
              <span>Create Subscription</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="71"
              onClick={() => dispatch(push("/Services-management"))}
            >
              <span>Services</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="72"
              onClick={() => dispatch(push("/plans-management"))}
            >
              <span>Plans</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="80"
              onClick={() => dispatch(push("/visit"))}
              style={{ marginBottom: "0px" }}
            >
              <span>Visits</span>
            </Menu.Item>
          </SubMenu> */}

          {/* Payments SubMenu */}
          <SubMenu
            key="sub2"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={payment} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "Payments"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="9"
              onClick={() => dispatch(push("/ar"))}
            >
              <span>Accounts Receivable</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="1"
              onClick={() => dispatch(push("/payment-requests"))}
            >
              <span>Payment Plans</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem w102p"
              key="141"
              onClick={() => dispatch(push("/transactions"))}
            >
              <span>Transactions</span>
              <Badge
                count={count.t_count ? count.t_count : 0}
                className="ml10"
                style={{ backgroundColor: "#9340ff" }}
              />
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem w102p"
              key="142"
              style={{ marginBottom: "0px" }}
              onClick={() => dispatch(push("/failed-payments"))}
            >
              <span>Plans In-Default</span>
              <Badge
                className="ml10"
                count={count.fp_count ? count.fp_count : 0}
                style={{ backgroundColor: "red" }}
              />
            </Menu.Item>
          </SubMenu>

          {/* Appointments SubMenu */}
          <SubMenu
            key="sub3"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={appointment} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "Appointments"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="6"
              onClick={() => dispatch(push("/appointments"))}
            >
              <span>Appointments</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="3"
              onClick={() => dispatch(push("/add-provider"))}
            >
              <span>Add Provider</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="5"
              onClick={() => dispatch(push("/provider-scheduling"))}
            >
              <span>Provider Scheduling</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="143"
              onClick={() => dispatch(push("/discount-options"))}
              style={{ marginBottom: "0px" }}
            >
              <span>Discount Options</span>
            </Menu.Item>
          </SubMenu>

          {/* My Office SubMenu */}
          <SubMenu
            key="sub5"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={office} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "My Office"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="8"
              onClick={() => dispatch(push("/office/profile"))}
            >
              <span>Edit Profile</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="15"
              onClick={() => dispatch(push("/office/appointments"))}
            >
              <span>Appointment Types</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="70"
              onClick={() => dispatch(push("/Services-management"))}
            >
              <span>Services</span>
            </Menu.Item>
            <Menu.Item
              className="sidebar_menuitem"
              key="12"
              onClick={() => dispatch(push("/office/review"))}
              style={{ marginBottom: "0px" }}
            >
              <span>Check Reviews</span>
            </Menu.Item>
          </SubMenu>

          {/* Data SubMenu */}
          {isPracticeAdmin ? (
            <SubMenu
              key="sub5-data"
              className="sidebar_submenu"
              title={
                <span className="align-menu">
                  <img src={ticket} alt="" className="Icon" />
                  <span style={{ fontSize: 18 }}>
                    {collapsed ? "" : "Data"}
                  </span>
                </span>
              }
            >
              <Menu.Item
                className="sidebar_menuitem"
                key="30"
                onClick={() => dispatch(push("/data/health-categories"))}
              >
                <span>Health Categories</span>
              </Menu.Item>
              <Menu.Item
                className="sidebar_menuitem"
                key="31"
                onClick={() => dispatch(push("/data/procedures"))}
              >
                <span>Procedures</span>
              </Menu.Item>
            </SubMenu>
          ) : null}

          {/* Patient Engagement SubMenu */}
          <SubMenu
            key="markettSub"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={market} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "Patient Engagement"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="campaigns"
              onClick={() => dispatch(push("/campaigns"))}
            >
              <span>{collapsed ? "" : "Engagement Campaigns"}</span>
            </Menu.Item>
          </SubMenu>

          {/* Settings SubMenu */}
          <SubMenu
            key="sub4"
            className="sidebar_submenu"
            title={
              <span className="align-menu">
                <img src={setting} alt="" className="Icon" />
                <span style={{ fontSize: 18 }}>
                  {collapsed ? "" : "Settings"}
                </span>
              </span>
            }
          >
            <Menu.Item
              className="sidebar_menuitem"
              key="4"
              onClick={() => dispatch(push("/add-connected-account"))}
            >
              <span>Configure Banking</span>
            </Menu.Item>
            {isPracticeAdmin ? (
              <Menu.Item
                className="sidebar_menuitem"
                key="132"
                onClick={() => dispatch(push("/offices"))}
              >
                <span>User Management</span>
              </Menu.Item>
            ) : null}
          </SubMenu>
        </Menu>
      </Sider>
      <Modal
        open={displaySidebar !== "none"}
        onCancel={() => {
          setDisplaySidebar("none");
        }}
        className="sidebar_modal"
      ></Modal>
    </div>
  );
}

function mapStateToProps(state) {
  const { sendingLoginEmail, loginEmailSent, loggingIn, error } =
    state.authentication;
  return {
    sendingLoginEmail,
    loginEmailSent,
    loggingIn,
    error,
  };
}

const connectedSidebar = connect(mapStateToProps)(Sidebar);

export default withRouter(connectedSidebar);
